<template>
  <div class="">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> 栏目管理</el-breadcrumb-item>
        <el-breadcrumb-item> 养殖信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <el-tabs v-model="message" type="card" @edit="tabRemove">
        <el-tab-pane label="养殖信息列表" name="first">
          <el-table
            :data="tableData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="column_id"
              label="ID"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="column_title"
              label="养殖信息标题"
            ></el-table-column>
            <!-- <el-table-column
              prop="column_content"
              label="养殖信息内容"
            ></el-table-column> -->

            <el-table-column
              prop="create_time"
              width="220"
              label="创建时间"
            ></el-table-column>
            <el-table-column label="操作" width="220" align="center">
              <template #default="scope">
                <el-button
                  type="text"
                  icon="el-icon-lx-copy"
                  @click="handleEdit(scope.$index, scope.row)"
                  >详情</el-button
                >
               
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  class="red"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              layout=" prev, pager, next"
              :current-page="query.pageIndex"
              :page-size="query.pageSize"
              :total="pageTotal"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="添加" name="second">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="150"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item label="养殖信息标题" prop="column_title">
              <el-input v-model="ruleForm.column_title"></el-input>
            </el-form-item>
            <!-- <el-form-item label="养殖信息内容">
              <TEditor :value="ruleForm.column_content" @input="(e)=>{ruleForm.column_content = e}" />
            </el-form-item> -->
            <el-form-item label="养殖户姓名" prop="contacts">
              <el-input v-model="ruleForm.contacts"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="contact_number">
              <el-input v-model="ruleForm.contact_number"></el-input>
            </el-form-item>
            <el-form-item label="海域" prop="address">
              <el-input v-model="ruleForm.address"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >添加</el-button
              >
              <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="详情" name="third" v-if="details" :closable="true">
          <el-form
            :model="ruleEditForm"
            :rules="rules"
            ref="ruleEditForm"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item label="养殖信息标题" prop="column_title">
              <el-input
                v-model="ruleEditForm.column_title"
                v-if="edit"
              ></el-input>
              <div v-else>{{ ruleEditForm.column_title }}</div>
            </el-form-item>
            <!-- <el-form-item label="养殖信息内容" prop="column_content">
              <TEditor
                v-if="edit"
                :value="ruleEditForm.column_content"
                 @input="(e)=>{ruleEditForm.column_content = e}"
              />
              <div v-else v-html="ruleEditForm.column_content"></div>
            </el-form-item> -->
            <el-form-item label="养殖户姓名" prop="contacts">
              <el-input v-model="ruleEditForm.contacts" v-if="edit"></el-input>
              <div v-else>{{ ruleEditForm.contacts }}</div>
            </el-form-item>
            <el-form-item label="手机号" prop="contact_number">
              <el-input
                v-model="ruleEditForm.contact_number"
                v-if="edit"
              ></el-input>
              <div v-else>{{ ruleEditForm.contact_number }}</div>
            </el-form-item>
            <el-form-item label="海域" prop="address">
              <el-input v-model="ruleEditForm.address" v-if="edit"></el-input>
              <div v-else>{{ ruleEditForm.address }}</div>
            </el-form-item>
            <el-form-item v-if="edit">
              <el-button type="primary" @click="saveEdit('ruleEditForm')"
                >完成</el-button
              >

              <el-button @click="edit = false">取消</el-button>
            </el-form-item>
            <el-form-item v-else>
              <el-button @click="edit = true">编辑</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane
          label="评论列表"
          name="four"
          v-if="comment"
          :closable="true"
        >
          <el-table :data="commentData" style="width: 100%">
            <el-table-column prop="user_name" label="用户" width="180">
            </el-table-column>
            <el-table-column prop="comment_content" label="评论详情">
            </el-table-column>
            <el-table-column label="操作" width="180" align="center">
              <template #default="scope">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  class="red"
                  @click="commentDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import editorVue from "../../components/editor.vue";
import {
  getColumnList,
  columnRemove,
  columnCreate,
  columnEdit,
  commentList,
  commentRemove,
} from "../../api/index";
export default {
  name: "tabs",
  components: { editorVue },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: {
            handlers: {
              // 表情符
              emoji: function () {},
              image: function (value) {
                if (value) {
                  // 点击事件转移到按钮
                  document.querySelector("#toolbar-img").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          "emoji-toolbar": true,
          "emoji-shortname": true,
        },
        placeholder: "请输入内容......",
      },
      query: {
        pageIndex: 1,
        pageSize: 10,
        c_type_id: 9,
      },
      pageTotal: 0,
      ruleForm: {
        column_title: "",
        column_content: "",
        contacts: "",
        contact_number: "",
        address: "",
      },
      ruleEditForm: {
        column_id: "",
        column_title: "",
        column_content: "",
        contacts: "",
        contact_number: "",
        address: "",
      },
      rules: {
        column_title: [
          { required: true, message: "请输入动态标题", trigger: "blur" },
        ],
        // column_content: [
        //   { required: true, message: "请输入动态内容", trigger: "blur" },
        // ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        contact_number: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
      },
      message: "first",
      details: false,
      edit: false,
      tableData: [],
      comment: false,
      commentData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
  
    tabRemove(name) {
      if (name == "third") {
        this.details = false;
      } else {
        this.comment = false;
      }

      this.message = "first";
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    getData() {
      console.log();
      let formdata = new FormData();
      formdata.append("c_type_id", this.query.c_type_id);
      formdata.append("pageIndex", this.query.pageIndex);
      formdata.append("pageSize", this.query.pageSize);
      formdata.append("token", localStorage.getItem("ms_token"));
      getColumnList(formdata).then((res) => {
        this.tableData = res.Data.Data;
        this.pageTotal = res.Data.Total;
      });
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑操作
    handleEdit(index, row) {
      this.message = "third";
      this.details = true;
      this.edit = false;
      console.log(index, row);
      this.idx = index;
      this.ruleEditForm.column_id = row.column_id;
      this.ruleEditForm.column_title = row.column_title;
      this.ruleEditForm.column_content = row.column_content;
      this.ruleEditForm.contacts = row.contacts;
      this.ruleEditForm.contact_number = row.contact_number;
      this.ruleEditForm.address = row.address;
      console.log(this.$refs);
    },
    saveEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formdata = new FormData();
        //   formdata.append("column_content", this.ruleEditForm.column_content);
          formdata.append("contacts", this.ruleEditForm.contacts);
          formdata.append("contact_number", this.ruleEditForm.contact_number);
          formdata.append("address", this.ruleEditForm.address);
          formdata.append("column_id", this.ruleEditForm.column_id);
          formdata.append("c_type_id", this.query.c_type_id);
          formdata.append("column_title", this.ruleEditForm.column_title);
          formdata.append("token", localStorage.getItem("ms_token"));
          columnEdit(formdata).then((res) => {
            if (res.Status == 200) {
              this.details = false;
              this.ruleEditForm.column_content = "";
              this.message = "first";
              this.getData();
              this.$message.success("操作成功");
            } else {
              this.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("column_id", row.column_id);
          formdata.append("token", localStorage.getItem("ms_token"));
          columnRemove(formdata).then((res) => {
            if (res.Status == 200) {
              this.$message.success("删除成功");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error(res.Message);
            }
          });
        })
        .catch(() => {});
    },
    // 添加
    submitForm(formName) {
      // console.log(this.$refs.Teditoradd)
      //  console.log(this.ruleForm.column_content)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formdata = new FormData();

          formdata.append("column_title", this.ruleForm.column_title);
        //   formdata.append("column_content", this.ruleForm.column_content);
          formdata.append("contacts", this.ruleForm.contacts);
          formdata.append("contact_number", this.ruleForm.contact_number);
          formdata.append("address", this.ruleForm.address);
          formdata.append("c_type_id", this.query.c_type_id);
          formdata.append("token", localStorage.getItem("ms_token"));
          columnCreate(formdata).then((res) => {
            if (res.Status == 200) {
              this.message = "first";
              this.ruleForm.column_title = "";
              this.ruleForm.column_content = "";
              this.getData();
            } else {
              this.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    getComment(index, row) {
      this.comment = true;
      this.message = "four";
      let formdata = new FormData();
      formdata.append("column_id", row.column_id);
      formdata.append("token", localStorage.getItem("ms_token"));
      commentList(formdata).then((res) => {
        if (res.Status == 200) {
          this.commentData = res.Data.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    commentDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("comment_id", row.comment_id);
          formdata.append("token", localStorage.getItem("ms_token"));
          commentRemove(formdata).then((res) => {
            if (res.Status == 200) {
              this.$message.success("删除成功");
              this.commentData.splice(index, 1);
            } else {
              this.$message.error(res.Message);
            }
          });
        })
        .catch(() => {});
    },
  },
  computed: {
    unreadNum() {
      return this.unread.length;
    },
  },
};
</script>

<style>
.message-title {
  cursor: pointer;
}
.handle-row {
  margin-top: 30px;
}
</style>

