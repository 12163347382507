<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          ><i class="el-icon-lx-cascades"></i>短信模板设置</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-form
        :model="about"
        :rules="rules"
        ref="about"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
       <el-form-item label="短信模板Key" prop="AccetKey">
          <el-input v-model="SMS.AccetKey" v-if="edit"></el-input>
          <div v-else>{{ SMS.AccetKey }}</div>
        </el-form-item>
        <el-form-item label="短信模板秘钥" prop="Accetserct">
          <el-input v-model="SMS.Accetserct" v-if="edit"></el-input>
          <div v-else>{{ SMS.Accetserct }}</div>
        </el-form-item>
        <el-form-item label="短信模板签名" prop="SignName">
          <el-input v-model="SMS.SignName" v-if="edit"></el-input>
          <div v-else>{{ SMS.SignName }}</div>
        </el-form-item>

        <el-form-item v-if="edit">
          <el-button type="primary" @click="saveEdit('SMS')">完成</el-button>

          <el-button @click="edit = false">取消</el-button>
        </el-form-item>
        <el-form-item v-else>
          <el-button @click="edit = true">编辑</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getSMSDetail, EditSMSDetail } from "../../api/index";
export default {
  name: "aboutUs",
  data() {
    return {
      SMS: {
     
        AccetKey: "",
        Accetserct: "",
        SignName: "",
      },
      edit: false,
      rules: {
        
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let formdata = new FormData();
      formdata.append("token", localStorage.getItem("ms_token"));
      getSMSDetail(formdata).then((res) => {
      
        this.SMS.AccetKey = res.Data.AccetKey;
        this.SMS.Accetserct = res.Data.Accetserct;
       this.SMS.SignName= res.Data.SignName;
      });
    },
    saveEdit(formName) {
     
         console.log("报错");
       
          let formdata = new FormData();

          formdata.append("AccetKey", this.SMS.AccetKey);
          formdata.append("Accetserct", this.SMS.Accetserct);
           formdata.append("SignName", this.SMS.SignName);
         
          formdata.append("token", localStorage.getItem("ms_token"));
          EditSMSDetail(formdata).then((res) => {
            if (res.Status == 200) {
              this.edit = false;
              this.$message.success("修改成功");
            } else {
              this.$message.error(res.Message);
            }
          });
       
        
      
    },
  },
};
</script>