<template>
  <div class="">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> 栏目管理</el-breadcrumb-item>
        <el-breadcrumb-item> 供应信息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <el-tabs v-model="message" type="card" @edit="tabRemove">
        <el-tab-pane label="供应信息列表" name="first">
          <el-table
            :data="tableData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            
            <el-table-column
              prop="column_id"
              label="ID"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="column_title"
              label="供应信息标题"
            ></el-table-column>
            <el-table-column label="封面图" width="150" align="center">
							<template #default="scope">
								<el-image class="table-td-thumb" :src=" url.baseUrl + scope.row.cover_map"
									:preview-src-list="[ url.baseUrl + scope.row.cover_map]"></el-image>
							</template>
						</el-table-column>
						<el-table-column label="审核状态" width="100" align="center">
          <template #default="scope">
            <el-tag v-if="scope.row.status == 1" type="" effect="plain">
             待审核
            </el-tag>
            <el-tag
              v-else-if="scope.row.status== 0"
              type="success"
              effect="plain"
            >
              审核成功
            </el-tag>
            <el-tag
              v-else-if="scope.row.status== 2"
              type="warning"
              effect="plain"
            >
              已拒绝
            </el-tag>
          </template>
        </el-table-column>
            <el-table-column  
			width="400"
              prop="column_content"
              label="供应信息内容"
            >
				<template #default="scope">
					<div v-html="scope.row.column_content"></div>
				</template>
			</el-table-column>

            <el-table-column
              prop="create_time"
              width="220"
              label="创建时间"
            ></el-table-column>
            <el-table-column label="操作" width="220" align="center">
              <template #default="scope">
				  <el-button type="text" icon="el-icon-lx-copy"
				  	@click="handleEdit(scope.$index, scope.row)">详情</el-button>
               <!--  <el-button
                  type="text"
                  icon="el-icon-lx-copy"
                  @click="getComment(scope.$index, scope.row)"
                  >评论</el-button
                > -->
                <el-button
              type="text"
               v-if="scope.row.status==1"
              icon="el-icon-refresh"
              @click="AuditUser(scope.$index, scope.row)"
              >审核</el-button
            >
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  class="red"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              layout=" prev, pager, next"
              :current-page="query.pageIndex"
              :page-size="query.pageSize"
              :total="pageTotal"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </el-tab-pane>

      <!-- 审核弹出框 -->
    <el-dialog title="审核" v-model="AuditVisible" width="30%">
      <el-form ref="Auditform" :model="Auditform" label-width="70px">

         <el-form-item span="10" >
            <el-form-item label="审核类型" >
              <el-radio-group size="medium" v-model="Auditform.status">
                <el-radio :label="0" :value="Auditform.status">审核通过 </el-radio>
                <el-radio :label="2">拒绝通过</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form-item>


      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="AuditVisible = false">取 消</el-button>
          <el-button type="primary" @click="AuditEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

		<el-tab-pane label="添加" name="second">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
				label-position="top">
				<el-form-item label="标题" prop="column_title">
					<el-input v-model="ruleForm.column_title"></el-input>
				</el-form-item>


      <!--  <el-form-item label="封面图" style="width:300px">
							<el-upload class="avatar-uploader" action="" :show-file-list="false"
								:before-upload="beforeAvatarUploadAdd" :on-change="onChangeAdd" :auto-upload="false">
								<img v-if="imageUrlAdd" :src="url.baseUrl +imageUrlAdd" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>-->
            
          <el-form-item label="封面图" >
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-change="onChange"
            :auto-upload="false"
          >
            <img v-if="imageUrl" :src="url.baseUrl +imageUrl" class="avatar"  style="width:100%;height:100%"/>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>




				<el-form-item label="内容" >
					<TEditor :value="ruleForm.column_content" @input="(e)=>{ruleForm.column_content = e}" />
				</el-form-item>
				<el-form-item label="联系人" prop="contacts">
					<el-input v-model="ruleForm.contacts"></el-input>
				</el-form-item>
				<el-form-item label="联系方式" prop="contact_number">
					<el-input v-model="ruleForm.contact_number"></el-input>
				</el-form-item>
				<el-form-item label="联系地址" prop="address">
					<el-input v-model="ruleForm.address"></el-input>
				</el-form-item>



         <el-form-item span="10" >
            <el-form-item label="审核状态" >
              <el-radio-group v-model="ruleForm.status" size="medium">
                <el-radio :label="0" :value="ruleForm.status">审核通过 </el-radio>
                 <el-radio :label="1" :value="ruleForm.status">待审核 </el-radio>
                <el-radio :label="2" :value="ruleForm.status">拒绝通过</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form-item>


			


				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
					<!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
				</el-form-item>
			</el-form>
		</el-tab-pane>
		<el-tab-pane label="详情" name="third" v-if="details" :closable="true">
			<el-form :model="ruleEditForm" :rules="rules" ref="ruleEditForm" label-width="100px"
				class="demo-ruleForm" label-position="top">
				<el-form-item label="标题" prop="column_title">
					<el-input v-model="ruleEditForm.column_title" v-if="edit"></el-input>
					<div v-else>{{ ruleEditForm.column_title }}</div>
				</el-form-item>
        <el-form-item label="封面图" style="width:300px">
							<el-upload class="avatar-uploader" action="" :show-file-list="false"
								:before-upload="beforeAvatarUploadEdit" :on-change="onChangeEdit" :auto-upload="false"
								v-if="edit">
								<img v-if="imageUrlEdit" :src="url.baseUrl +imageUrlEdit" class="avatar" style="width:100%;height:100%" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<img v-else :src="url.baseUrl +imageUrlEdit" class="avatar" />
						</el-form-item>
				<el-form-item label="内容" prop="column_content">
					<TEditor :value="ruleEditForm.column_content" v-if="edit"
						@input="(e)=>{ruleEditForm.column_content = e}" />
					<div v-else v-html="ruleEditForm.column_content"></div>
				</el-form-item>
				<el-form-item label="联系人" prop="contacts">
					<el-input v-model="ruleEditForm.contacts" v-if="edit"></el-input>
					<div v-else>{{ ruleEditForm.contacts }}</div>
				</el-form-item>
				<el-form-item label="联系方式" prop="contact_number">
					<el-input v-model="ruleEditForm.contact_number" v-if="edit"></el-input>
					<div v-else>{{ ruleEditForm.contact_number }}</div>
				</el-form-item>
				<el-form-item label="联系地址" prop="address">
					<el-input v-model="ruleEditForm.address" v-if="edit"></el-input>
					<div v-else>{{ ruleEditForm.address }}</div>
				</el-form-item>
				
            <el-form-item span="10" >
            <el-form-item label="审核状态" >
              <el-radio-group v-model="ruleEditForm.status" size="medium" >
                 <el-radio :label="0" :value="ruleEditForm.status" >审核通过 </el-radio>
				 <el-radio :label="1" :value="ruleEditForm.status" >待审核 </el-radio>
                <el-radio :label="2" :value="ruleEditForm.status">审核拒绝</el-radio>
              </el-radio-group>
			   
            </el-form-item>
          </el-form-item>

				<el-form-item v-if="edit">
					<el-button type="primary" @click="saveEdit('ruleEditForm')">完成</el-button>
					<!-- <el-button @click="resetForm('ruleEditForm')">重置</el-button> -->
					<el-button @click="edit = false">取消</el-button>
				</el-form-item>
				<el-form-item v-else>
					<el-button @click="edit = true">编辑</el-button>
				</el-form-item>
			</el-form>
		</el-tab-pane>
        <el-tab-pane
          label="评论列表"
          name="four"
          v-if="comment"
          :closable="true"
        >
          <el-table :data="commentData" style="width: 100%">
            <el-table-column prop="user_name" label="用户" width="180">
            </el-table-column>
            <el-table-column prop="comment_content" label="评论详情">
            </el-table-column>
            <el-table-column label="操作" width="180" align="center">
              <template #default="scope">
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  class="red"
                  @click="commentDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import editorVue from "../../components/editor.vue";
import url from "../../api/baseUrl";
import {
  getColumnList,
  columnCreate,
  columnEdit,
  columnRemove,
  commentList,
  commentRemove,
  Auditcolumn,
  fileUpload,
} from "../../api/index";
export default {
  name: "tabs",
  components: { editorVue },
  data() {
     var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入联系方式"));
      } else {
        if (this.ruleForm.contact_number !== "") {
          this.$refs.ruleForm.validateField("contact_number");
        }
        callback();
      }
    };
    return {
     url: '',
      query: {
        pageIndex: 1,
        pageSize: 10,
        c_type_id: 7,
      },
      pageTotal: 0,
	  ruleForm: {
	  	column_title: "",
	  	column_content: "",
	  		contacts: "",
	  		contact_number: "",
	  		address: "",
			  status:0,
	  },
	     AuditVisible: false,
	  ruleEditForm: {
	  	column_id: "",
	  	column_title: "",
	  	column_content: "",
	  		contacts: "",
	  		contact_number: "",
	  		address: "",
			   status:0,
	  },
	  rules: {
	  	column_title: [{
	  		required: true,
	  		message: "请输入标题",
	  		trigger: "blur"
	  	}, ],
	 	column_content: [{
	  		required: true,
	  		message: "请输入内容",
	  		trigger: "blur"
	  	}, ],
	  		contacts: [{
	  			required: true,
	  			message: "请输入联系人",
	  			trigger: "blur"
	  		}, ],
	  		
        contact_number: [
          { validator: validatePass, trigger: "blur" },
          {
            	required: true,
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "联系方式格式不对",
            trigger: "blur",
          },
        ],
	  		address: [{
	  			required: true,
	  			message: "请输入联系地址",
	  			trigger: "blur"
	  		}, ],
	  },
      message: "first",
	  details: false,
	  edit: false, 
	  Auditform: {
        status: 0,
       
        column_id: "",
        
      },
       imageUrl: "",
    imageUrlAdd: "",
				imageUrlEdit: "",
      tableData: [],
      comment: false,
      commentData: [],
    };
  },
  created() {
    this.getData();
    this.url = url
  },
  methods: {
   
    tabRemove(name) {
      if (name == "third") {
        this.details = false;
      } else {
        this.comment = false;
      }

      this.message = "first";
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
	   //设置审核
    AuditUser(index, row) {
      this.AuditVisible=true;
     this.Auditform.column_id = row.column_id;
    },
	 AuditEdit(){
      let formdata = new FormData();
      formdata.append("column_id", this.Auditform.column_id);
      formdata.append("status", this.Auditform.status);
      formdata.append("token", localStorage.getItem("ms_token"));
      Auditcolumn(formdata).then((res) => {
        if (res.Status == 200) {
           this.getData();
          this.AuditVisible=false;
     
          this.Auditform.column_id = "";
          this.Auditform.status = 0;
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.Message);
        }
      });
   },
    getData() {
      console.log();
      let formdata = new FormData();
      formdata.append("c_type_id", this.query.c_type_id);
      formdata.append("pageIndex", this.query.pageIndex);
      formdata.append("pageSize", this.query.pageSize);
      formdata.append("token", localStorage.getItem("ms_token"));
      getColumnList(formdata).then((res) => {
        this.tableData = res.Data.Data;
        this.pageTotal = res.Data.Total;
      });
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑操作
   
  onChange(file) {
       console.log("onChange");
      console.log(file);
      this.file = file;
      const fileObj = this.file !== null ? this.file.raw : null;
      const form = new FormData();
      // 文件对象
      form.append("file", fileObj);
      form.append("file_type", 1);
      form.append("token", localStorage.getItem("ms_token"));
      // 调用保存接口 将form的值全都传过去
      console.log("form");
       console.log(form);
      fileUpload(form).then((res) => {
        this.imageUrl =  res.Data.file_url;
      });
    },
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("column_id", row.column_id);
          formdata.append("token", localStorage.getItem("ms_token"));
          columnRemove(formdata).then((res) => {
            if (res.Status == 200) {
              this.$message.success("删除成功");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error(res.Message);
            }
          });
        })
        .catch(() => {});
    },

	// 添加
	submitForm(formName) {
   // this.imageUrl="";
		if (this.ruleForm.column_content == "") {
			this.$message.error("请填写内容详情");
      return;
		}
     if (this.imageUrl == "") {
        this.$message.error("请上传头像！");
        return;
      } 
       
		this.$refs[formName].validate((valid) => {
			if (valid) {
				let formdata = new FormData();
				formdata.append("column_title", this.ruleForm.column_title);
				formdata.append("column_content", this.ruleForm.column_content);
				formdata.append("contacts", this.ruleForm.contacts);
				formdata.append("contact_number", this.ruleForm.contact_number);
				formdata.append("address", this.ruleForm.address);
					formdata.append("status", this.ruleForm.status);
				formdata.append("c_type_id", this.query.c_type_id);
        	formdata.append("cover_map", this.imageUrl);
				formdata.append("token", localStorage.getItem("ms_token"));
				columnCreate(formdata).then((res) => {
					if (res.Status == 200) {
						this.message = "first";
						this.ruleForm.column_title = "";
						this.ruleForm.column_content = "";
						this.ruleForm.contacts = "";
						this.ruleForm.contact_number = "";
						this.ruleForm.address = "";
						this.getData();
					} else {
						this.$message.error(res.Message);
					}
				});
			} else {
				console.log("error submit!!");
				return false;
			}
		});
	},
 beforeAvatarUpload(file) {
         console.log("beforeAvatarUpload");
       console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },

  	beforeAvatarUploadAdd(file) {
				const isJPG = file.type === "image/jpeg";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
      onChangeEdit(file) {
				this.file = file;
				const fileObj = this.file !== null ? this.file.raw : null;
				const form = new FormData();
				// 文件对象
				form.append("file", fileObj);
				form.append("file_type", 2);
				form.append("token", localStorage.getItem("ms_token"));
				// 调用保存接口 将form的值全都传过去
				fileUpload(form).then((res) => {
					this.imageUrlEdit = res.Data.file_url;
				});
			},

	// 编辑操作
	handleEdit(index, row) {
		this.message = "third";
		this.details = true;
		this.edit = false;
		this.idx = index;
		this.ruleEditForm.column_id = row.column_id;
		this.ruleEditForm.column_title = row.column_title;
		this.ruleEditForm.column_content = row.column_content;
		this.ruleEditForm.contacts = row.contacts;
		this.ruleEditForm.contact_number = row.contact_number;
		this.ruleEditForm.address = row.address;
    	this.imageUrlEdit = row.cover_map;
		this.ruleEditForm.status = row.status;
			
	},
	saveEdit(formName) {
		this.$refs[formName].validate((valid) => {
			if (valid) {
				if (this.ruleEditForm.column_content == "") {
					this.$message.error("请填写内容详情");
				}
				let formdata = new FormData();
				formdata.append("column_title", this.ruleEditForm.column_title);
				formdata.append("column_content", this.ruleEditForm.column_content);
				formdata.append("contacts", this.ruleEditForm.contacts);
				formdata.append("contact_number", this.ruleEditForm.contact_number);
				formdata.append("address", this.ruleEditForm.address);
				formdata.append("column_id", this.ruleEditForm.column_id);
				formdata.append("c_type_id", this.query.c_type_id);
				formdata.append("status", this.ruleEditForm.status);
				formdata.append("token", localStorage.getItem("ms_token"));
        	formdata.append("cover_map", this.imageUrlEdit);
				columnEdit(formdata).then((res) => {
					if (res.Status == 200) {
						this.details = false;
						this.ruleEditForm.column_title = "";
						this.ruleEditForm.column_content = "";
						this.ruleEditForm.contacts = "";
						this.ruleEditForm.contact_number = "";
						this.ruleEditForm.address = "";
						this.message = "first";
						this.getData();
						this.$message.success("操作成功");
					} else {
						this.$message.error(res.Message);
					}
				});
			} else {
				console.log("error submit!!");
				return false;
			}
		});
	},

     getComment(index, row) {
      this.comment = true;
      this.message = "four";
      let formdata = new FormData();
      formdata.append("column_id", row.column_id);
      formdata.append("token", localStorage.getItem("ms_token"));
      commentList(formdata).then((res) => {
        if (res.Status == 200) {
          this.commentData = res.Data.Data;
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    commentDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("comment_id", row.comment_id);
          formdata.append("token", localStorage.getItem("ms_token"));
          commentRemove(formdata).then((res) => {
            if (res.Status == 200) {
              this.$message.success("删除成功");
              this.commentData.splice(index, 1);
            } else {
              this.$message.error(res.Message);
            }
          });
        })
        .catch(() => {});
    },
  },
  computed: {
    unreadNum() {
      return this.unread.length;
    },
  },
};
</script>

<style>
.message-title {
  cursor: pointer;
}
.handle-row {
  margin-top: 30px;
}
</style>

