<template>
  <div class="">
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item> 公告管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <el-tabs v-model="message" type="card" @edit="tabRemove">
        <el-tab-pane label="公告列表" name="first">
          <el-table
            :data="tableData"
            border
            class="table"
            ref="multipleTable"
            header-cell-class-name="table-header"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
              type="selection"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="notice_id"
              label="ID"
              width="55"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="notice_title"
              label="公告标题"
            ></el-table-column>
            <!-- <el-table-column
              prop="notice_content"
              label="公告内容"
            ></el-table-column> -->

            <el-table-column
              prop="create_time"
              width="220"
              label="创建时间"
            ></el-table-column>
            <el-table-column label="操作" width="180" align="center">
              <template #default="scope">
                <el-button
                  type="text"
                  icon="el-icon-lx-copy"
                  @click="handleEdit(scope.$index, scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  class="red"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              background
              layout=" prev, pager, next"
              :current-page="query.pageIndex"
              :page-size="query.pageSize"
              :total="pageTotal"
              @current-change="handlePageChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="添加" name="second">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item label="公告标题" prop="column_title">
              <el-input v-model="ruleForm.notice_title"></el-input>
            </el-form-item>
            <el-form-item label="公告内容">
              <TEditor :value="ruleForm.notice_content"  @input="(e)=>{ruleForm.notice_content = e}"  />
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="submitForm('ruleForm')"
                >添加</el-button
              >
              <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="详情" name="third" v-if="details" :closable="true">
          <el-form
            :model="ruleEditForm"
            :rules="rules"
            ref="ruleEditForm"
            label-width="100px"
            class="demo-ruleForm"
            label-position="top"
          >
            <el-form-item label="公告标题" prop="notice_title">
              <el-input
                v-model="ruleEditForm.notice_title"
                v-if="edit"
              ></el-input>
              <div v-else>{{ ruleEditForm.notice_title }}</div>
            </el-form-item>
            <el-form-item label="公告内容" prop="notice_content">
              <TEditor
                v-if="edit"
                :value="ruleEditForm.notice_content"
               @input="(e)=>{ruleEditForm.notice_content = e}" 
              />
              <div v-else v-html="ruleEditForm.notice_content"></div>
            </el-form-item>
            <el-form-item v-if="edit">
              <el-button type="primary" @click="saveEdit('ruleEditForm')"
                >完成</el-button
              >
              <el-button @click="edit = false">取消</el-button>
            </el-form-item>
            <el-form-item v-else>
              <el-button @click="edit = true">编辑</el-button>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import editorVue from "../../components/editor.vue";
import {
  getNoticeList,
  noticeRemove,
  noticeAdd,
  noticeEdit,
} from "../../api/index";
export default {
  name: "tabs",
  components: { editorVue },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: {
            handlers: {
              // 表情符
              emoji: function () {},
              image: function (value) {
                if (value) {
                  // 点击事件转移到按钮
                  document.querySelector("#toolbar-img").click();
                } else {
                  this.quill.format("image", false);
                }
              },
            },
          },
          imageResize: {
            modules: ["Resize", "DisplaySize", "Toolbar"],
          },
          "emoji-toolbar": true,
          "emoji-shortname": true,
        },
        placeholder: "请输入内容......",
      },
      query: {
        pageIndex: 1,
        pageSize: 10,
        c_type_id: 1,
      },
      pageTotal: 0,
      ruleForm: {
        notice_title: "",
        notice_content: "",
      },
      ruleEditForm: {
        notice_id: "",
        notice_title: "",
        notice_content: "",
      },
      rules: {
        notice_title: [
          { required: true, message: "请输入公告标题", trigger: "blur" },
        ],
        notice_content: [
          { required: true, message: "请输入公告内容", trigger: "blur" },
        ],
      },
      message: "first",
      details: false,
      edit: false,
      tableData: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    tabRemove() {
      this.details = false;
      this.message = "first";
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    getData() {
      console.log();
      let formdata = new FormData();
      formdata.append("c_type_id", this.query.c_type_id);
      formdata.append("pageIndex", this.query.pageIndex);
      formdata.append("pageSize", this.query.pageSize);
      formdata.append("token", localStorage.getItem("ms_token"));
      getNoticeList(formdata).then((res) => {
        this.tableData = res.Data.Data;
        this.pageTotal = res.Data.Total;
      });
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 编辑操作
    handleEdit(index, row) {
      this.message = "third";
      this.details = true;
      this.edit = false;
      console.log(index, row);
      this.idx = index;
      this.ruleEditForm.notice_id = row.notice_id;
      this.ruleEditForm.notice_title = row.notice_title;
      this.ruleEditForm.notice_content = row.notice_content;
      console.log(this.$refs);
    },
    saveEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formdata = new FormData();
          formdata.append("notice_id", this.ruleEditForm.notice_id);
          formdata.append("notice_content", this.ruleEditForm.notice_content);
          formdata.append("c_type_id", this.query.c_type_id);
          formdata.append("notice_title", this.ruleEditForm.notice_title);
          formdata.append("token", localStorage.getItem("ms_token"));
          noticeEdit(formdata).then((res) => {
            if (res.Status == 200) {
              this.details = false;
              this.ruleEditForm.notice_content = "";
              this.ruleEditForm.notice_content = "";
              this.message = "first";
              this.getData();
              this.$message.success("操作成功");
            } else {
              this.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("notice_id", row.notice_id);
          formdata.append("token", localStorage.getItem("ms_token"));
          noticeRemove(formdata).then((res) => {
            if (res.Status == 200) {
              this.$message.success("删除成功");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error(res.Message);
            }
          });
        })
        .catch(() => {});
    },
    // 添加
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formdata = new FormData();
          // console.log(this.ruleForm.notice_content,)
          formdata.append("notice_title", this.ruleForm.notice_title);
          formdata.append("notice_content", this.ruleForm.notice_content);
          formdata.append("c_type_id", this.query.c_type_id);
          formdata.append("token", localStorage.getItem("ms_token"));
          noticeAdd(formdata).then((res) => {
            if (res.Status == 200) {
              this.message = "first";
              this.ruleForm.notice_title = "";
              this.ruleForm.notice_content = "";
              this.getData();
            } else {
              this.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  computed: {
    unreadNum() {
      return this.unread.length;
    },
  },
};
</script>

<style>
.message-title {
  cursor: pointer;
}
.handle-row {
  margin-top: 30px;
}
</style>

