<template>
  <div>
    <div class="container">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="fullName">
          <el-input v-model="ruleForm.fullName"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { fullnameEdit } from "../../api/index";
export default {
  name: "uploadPwd",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        if (this.ruleForm.fullName !== "") {
          this.$refs.ruleForm.validateField("fullName");
        }
        callback();
      }
    };

    return {
      ruleForm: {
        fullName: "",
      },
      rules: {
        fullName: [{ validator: validatePass, trigger: "blur" }],
      },
    };
  },
  methods: {
    submitForm(formName) {
      if (this.ruleForm.fullName == "") {
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formdata = new FormData();
          formdata.append("full_name", this.ruleForm.fullName);
          formdata.append("token", localStorage.getItem("ms_token"));
          console.log(this.ruleForm.fullName);
          fullnameEdit(formdata).then((res) => {
            if (res.Status == 200) {
              localStorage.setItem("ms_username",this.ruleForm.fullName);

              this.$message.success("修改成功");
              
            } else {
              this.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {},
};
</script>

<style scoped>
</style>