<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          ><i class="el-icon-lx-cascades"></i>关于我们</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-form
        :model="about"
        :rules="rules"
        ref="about"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-form-item label="邮箱" prop="mailbox">
          <el-input v-model="about.mailbox" v-if="edit"></el-input>
          <div v-else>{{ about.mailbox }}</div>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="about.phone" v-if="edit"></el-input>
          <div v-else>{{ about.phone }}</div>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <TEditor
            v-if="edit"
            :value="about.content"
            @input="
              (e) => {
                about.content = e;
              }
            "
          />
          <div v-else v-html="about.content"></div>
        </el-form-item>

        <el-form-item v-if="edit">
          <el-button type="primary" @click="saveEdit('about')">完成</el-button>

          <el-button @click="edit = false">取消</el-button>
        </el-form-item>
        <el-form-item v-else>
          <el-button @click="edit = true">编辑</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getDictionaries, editDictionaries } from "../../api/index";
export default {
  name: "aboutUs",
  data() {
    return {
      about: {
        content: "",
        mailbox: "",
        mailbox: "",
        phone: "",
      },
      edit: false,
      rules: {
        mailbox: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: "邮箱格式不对",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let formdata = new FormData();
      formdata.append("token", localStorage.getItem("ms_token"));
      getDictionaries(formdata).then((res) => {
        this.about.content = res.Data.about_us;
        this.about.phone = res.Data.phone;
        this.about.mailbox = res.Data.mailbox;
      });
    },
    saveEdit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formdata = new FormData();
          formdata.append("set_id", 1);
          formdata.append("phone", this.about.phone);
          formdata.append("mailbox", this.about.mailbox);
          formdata.append("about_us", this.about.content);
          formdata.append("token", localStorage.getItem("ms_token"));
          editDictionaries(formdata).then((res) => {
            if (res.Status == 200) {
              this.edit = false;
              this.$message.success("修改成功");
            } else {
              this.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>