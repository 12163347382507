<template>
	<div class="">
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item> 栏目管理</el-breadcrumb-item>
				<el-breadcrumb-item> 养殖户信息</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="container">
			<el-tabs v-model="message" type="card" @edit="tabRemove">
				<el-tab-pane label="养殖户列表" name="first">
					<el-table :data="tableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="column_id" label="ID" width="55" align="center"></el-table-column>
						<el-table-column prop="column_title" label="养殖户姓名"></el-table-column>
						<el-table-column prop="contact_number" width="150" label="手机号码"></el-table-column>
						<el-table-column prop="check_code" width="200" label="检测编号"></el-table-column>
						<el-table-column label="检测报告" width="100" align="center">
							<template #default="scope">
								<el-image class="table-td-thumb" :src="url.baseUrl + scope.row.check_report"
									:preview-src-list="[url.baseUrl + scope.row.check_report]"></el-image>
							</template>
						</el-table-column>
						<el-table-column prop="create_time" width="200" label="创建时间"></el-table-column>
						<el-table-column label="操作" width="220" align="center">
							<template #default="scope">
								<el-button type="text" icon="el-icon-lx-copy"
									@click="handleEdit(scope.$index, scope.row)">详情</el-button>
								<el-button type="text" icon="el-icon-delete" class="red"
									@click="handleDelete(scope.$index, scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout=" prev, pager, next" :current-page="query.pageIndex"
							:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange">
						</el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="添加" name="second">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
						label-position="top">
						<el-form-item label="养殖户姓名" prop="column_title">
							<el-input v-model="ruleForm.column_title"></el-input>
						</el-form-item>
						<el-form-item label="手机号码" prop="contact_number">
							<el-input v-model="ruleForm.contact_number"></el-input>
						</el-form-item>
						<el-form-item label="检测编号" prop="check_code">
							<el-input v-model="ruleForm.check_code"></el-input>
						</el-form-item>
						<el-form-item label="检测报告" style="width:300px">
							<el-upload class="avatar-uploader" action="" :show-file-list="false"
								:before-upload="beforeAvatarUploadAdd" :on-change="onChangeAdd" :auto-upload="false">
								<img v-if="imageUrlAdd" :src="url.baseUrl +imageUrlAdd" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>

						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
							<!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="详情" name="third" v-if="details" :closable="true">
					<el-form :model="ruleEditForm" :rules="rules" ref="ruleEditForm" label-width="100px"
						class="demo-ruleForm" label-position="top">
						<el-form-item label="养殖户姓名" prop="column_title">
							<el-input v-model="ruleEditForm.column_title" v-if="edit"></el-input>
							<div v-else>{{ ruleEditForm.column_title }}</div>
						</el-form-item>
						<el-form-item label="手机号码" prop="contact_number">
							<el-input v-model="ruleEditForm.contact_number" v-if="edit"></el-input>
							<div v-else>{{ ruleEditForm.contact_number }}</div>
						</el-form-item>
						<el-form-item label="检测编号" prop="check_code">
							<el-input v-model="ruleEditForm.check_code" v-if="edit"></el-input>
							<div v-else>{{ ruleEditForm.check_code }}</div>
						</el-form-item>
						<el-form-item label="检测报告" style="width:300px">
							<el-upload class="avatar-uploader" action="" :show-file-list="false"
								:before-upload="beforeAvatarUploadEdit" :on-change="onChangeEdit" :auto-upload="false"
								v-if="edit">
								<img v-if="imageUrlEdit" :src="url.baseUrl +imageUrlEdit" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<img v-else :src="url.baseUrl +imageUrlEdit" class="avatar" />
						</el-form-item>

						<el-form-item v-if="edit">
							<el-button type="primary" @click="saveEdit('ruleEditForm')">完成</el-button>
							<!-- <el-button @click="resetForm('ruleEditForm')">重置</el-button> -->
							<el-button @click="edit = false">取消</el-button>
						</el-form-item>
						<el-form-item v-else>
							<el-button @click="edit = true">编辑</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="评论列表" name="four" v-if="comment" :closable="true">
					<el-table :data="commentData" style="width: 100%">
						<el-table-column prop="user_name" label="用户" width="180">
						</el-table-column>
						<el-table-column prop="comment_content" label="评论详情">
						</el-table-column>
						<el-table-column label="操作" width="180" align="center">
							<template #default="scope">
								<el-button type="text" icon="el-icon-delete" class="red"
									@click="commentDelete(scope.$index, scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import editorVue from "../../components/editor.vue";
	import {
		getColumnList,
		columnRemove,
		columnCreate,
		columnEdit,
		fileUpload,
		commentList,
		commentRemove,
	} from "../../api/index";
	import url from "../../api/baseUrl";
	export default {
		name: "tabs",
		components: {
			editorVue
		},
		data() {
			let checkPhone = (rule, value, callback) => {
				if (!value || value == '') {
					return callback(new Error('请填写手机号码'));
				}
				let regPhone = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
				if (!regPhone.test(value)) {
				    return callback(new Error('请填写正确的手机号码'));
				}
				return true;
			};
			return {
				url: '',
				query: {
					pageIndex: 1,
					pageSize: 10,
					c_type_id: 11,
				},
				pageTotal: 0,
				ruleForm: {
					column_title: "",
					contact_number: "",
					check_code: "",
				},
				ruleEditForm: {
					column_id: "",
					column_title: "",
					contact_number: "",
					check_code: "",
				},
				rules: {
					column_title: [{
						required: true,
						message: "请填写养殖户姓名",
						trigger: "blur"
					}],
					contact_number: [{
						validator: checkPhone,
						trigger: "blur"
					}],
					check_code: [{
						required: true,
						message: "请填写检测编号",
						trigger: "blur"
					}],
				},
				message: "first",
				details: false,
				edit: false,
				tableData: [],
				imageUrlAdd: "",
				imageUrlEdit: "",
				comment: false,
				commentData: [],
			};
		},
		created() {
			this.getData();
			this.url = url
		},
		methods: {
			tabRemove(name) {
				if (name == "third") {
					this.details = false;
				} else if (name == 'four') {
					this.comment = false;
				}
				this.message = "first";
			},
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			getData() {
				let formdata = new FormData();
				formdata.append("c_type_id", this.query.c_type_id);
				formdata.append("pageIndex", this.query.pageIndex);
				formdata.append("pageSize", this.query.pageSize);
				formdata.append("token", localStorage.getItem("ms_token"));
				getColumnList(formdata).then((res) => {
					this.tableData = res.Data.Data;
					this.pageTotal = res.Data.Total;
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 编辑操作
			handleEdit(index, row) {
				this.message = "third";
				this.details = true;
				this.edit = false;
				this.idx = index;
				this.ruleEditForm.column_id = row.column_id;
				this.ruleEditForm.column_title = row.column_title;
				this.ruleEditForm.contact_number = row.contact_number;
				this.ruleEditForm.check_code = row.check_code;
				this.imageUrlEdit = row.check_report;
			},
			saveEdit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.imageUrlEdit == "") {
							this.$message.error("请上传检测报告");
							return;
						}
						let formdata = new FormData();
						formdata.append("column_id", this.ruleEditForm.column_id);
						formdata.append("column_content", '');
						formdata.append("c_type_id", this.query.c_type_id);
						formdata.append("check_report", this.imageUrlEdit);
						formdata.append("column_title", this.ruleEditForm.column_title);
						formdata.append("contact_number", this.ruleEditForm.contact_number);
						formdata.append("check_code", this.ruleEditForm.check_code);
						formdata.append("token", localStorage.getItem("ms_token"));
						columnEdit(formdata).then((res) => {
							if (res.Status == 200) {
								this.details = false;
								this.message = "first";
								this.ruleForm.column_title = "";
								this.ruleForm.contact_number = "";
								this.ruleForm.check_code = "";
								this.imageUrlEdit = "";
								this.getData();
								this.$message.success("操作成功");
							} else {
								this.$message.error(res.Message);
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			handleDelete(index, row) {
				// 二次确认删除
				this.$confirm("确定要删除吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						let formdata = new FormData();
						formdata.append("column_id", row.column_id);
						formdata.append("token", localStorage.getItem("ms_token"));
						columnRemove(formdata).then((res) => {
							if (res.Status == 200) {
								this.$message.success("删除成功");
								this.tableData.splice(index, 1);
							} else {
								this.$message.error(res.Message);
							}
						});
					})
					.catch(() => {});
			},
			// 添加
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						if (this.imageUrlAdd == "") {
							this.$message.error("请上传检测报告");
							return;
						}
						let formdata = new FormData();
						formdata.append("column_title", this.ruleForm.column_title);
						formdata.append("column_content", '');
						formdata.append("contact_number", this.ruleForm.contact_number);
						formdata.append("check_code", this.ruleForm.check_code);
						formdata.append("check_report", this.imageUrlAdd);
						formdata.append("c_type_id", this.query.c_type_id);
						formdata.append("token", localStorage.getItem("ms_token"));
						columnCreate(formdata).then((res) => {
							if (res.Status == 200) {
								this.message = "first";
								this.ruleForm.column_title = "";
								this.ruleForm.contact_number = "";
								this.ruleForm.check_code = "";
								this.imageUrlAdd = "";
								this.getData();
							} else {
								this.$message.error(res.Message);
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			beforeAvatarUploadAdd(file) {
				const isJPG = file.type === "image/jpeg";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传头像图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传头像图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			onChangeAdd(file) {
				this.file = file;
				const fileObj = this.file !== null ? this.file.raw : null;
				const form = new FormData();
				// 文件对象
				form.append("file", fileObj);
				form.append("file_type", 1);
				form.append("token", localStorage.getItem("ms_token"));
				// 调用保存接口 将form的值全都传过去
				fileUpload(form).then((res) => {
					this.imageUrlAdd = res.Data.file_url;
				});
			},
			beforeAvatarUploadEdit(file) {
				const isJPG = file.type === "image/jpeg";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传封面图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传封面图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			onChangeEdit(file) {
				this.file = file;
				const fileObj = this.file !== null ? this.file.raw : null;
				const form = new FormData();
				// 文件对象
				form.append("file", fileObj);
				form.append("file_type", 1);
				form.append("token", localStorage.getItem("ms_token"));
				// 调用保存接口 将form的值全都传过去
				fileUpload(form).then((res) => {
					this.imageUrlEdit = res.Data.file_url;
				});
			},

			getComment(index, row) {
				this.comment = true;
				this.message = "four";
				let formdata = new FormData();
				formdata.append("column_id", row.column_id);
				formdata.append("token", localStorage.getItem("ms_token"));
				commentList(formdata).then((res) => {
					if (res.Status == 200) {
						this.commentData = res.Data.Data;
					} else {
						this.$message.error(res.Message);
					}
				});
			},

			commentDelete(index, row) {
				// 二次确认删除
				this.$confirm("确定要删除吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						let formdata = new FormData();
						formdata.append("comment_id", row.comment_id);
						formdata.append("token", localStorage.getItem("ms_token"));
						commentRemove(formdata).then((res) => {
							if (res.Status == 200) {
								this.$message.success("删除成功");
								this.commentData.splice(index, 1);
							} else {
								this.$message.error(res.Message);
							}
						});
					})
					.catch(() => {});
			},
		},
		computed: {
			unreadNum() {
				return this.unread.length;
			},
		},
	};
</script>

<style>
	.message-title {
		cursor: pointer;
	}

	.handle-row {
		margin-top: 30px;
	}

	.avatar {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
</style>
