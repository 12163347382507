<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 用户管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <!-- <el-button
          type="primary"
          icon="el-icon-delete"
          class="handle-del mr10"
          @click="delAllSelection"
          >批量删除</el-button
        > -->
        <el-date-picker
          v-model="query.startTime"
          type="date"
          placeholder="开始时间"
        >
        </el-date-picker>
        <el-date-picker
          v-model="query.endTime"
          type="date"
          placeholder="结束时间"
        >
        </el-date-picker>
        <el-select v-model="query.type" class="handle-select mr10">
          <el-option key="1" label="用户名" value="用户名"></el-option>
          <el-option key="2" label="手机号" value="手机号"></el-option>
        </el-select>

        <el-input
          v-model="query.search"
          :placeholder="query.type"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addclick"
          >添加</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column prop="user_name" label="用户名"></el-table-column>

        <el-table-column label="头像(查看大图)" align="center">
          <template #default="scope">
           <el-image v-if="scope.row.user_headportrait!=null"
              class="table-td-thumb"
              :src="url.baseUrl +scope.row.user_headportrait"
              :preview-src-list="[url.baseUrl +scope.row.user_headportrait]"
            ></el-image>
            <el-image v-else
              class="table-td-thumb"
              :src="'/img/img.146655c9.jpg'"
              :preview-src-list="'/img/img.146655c9.jpg'"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="user_phone" label="手机号"></el-table-column>
        <el-table-column label="用户权限" align="center">
          <template #default="scope">
            <el-tag v-if="scope.row.user_type == 1" type="" effect="plain">
              后台管理员
            </el-tag>
            <el-tag
              v-else-if="scope.row.user_type == 2"
              type="success"
              effect="plain"
            >
              操作员
            </el-tag>
            <el-tag
              v-else-if="scope.row.user_type == 3&&scope.row.is_vip==1"
              type="info"
              effect="plain"
            >
               个人会员
            </el-tag>
             <el-tag
              v-else-if="scope.row.user_type == 3&&scope.row.is_vip==2&&scope.row.apply_status==1"
              type="info"
              effect="plain"
            >
               企业会员待审核
            </el-tag>
            <el-tag
              v-else-if="scope.row.user_type == 3&&scope.row.is_vip==2&&scope.row.apply_status==2"
              type="info"
              effect="plain"
            >
               企业会员
            </el-tag>
             <el-tag
              v-else-if="scope.row.user_type == 3&&scope.row.is_vip==2&&scope.row.apply_status==3"
              type="info"
              effect="plain"
            >
               企业会员已拒绝
            </el-tag>
            <el-tag
              v-else-if="scope.row.user_type == 4"
              type="warning"
              effect="plain"
            >
              专家
            </el-tag>
            <el-tag
              v-else-if="scope.row.user_type == 5"
              type="warning"
              effect="plain"
            >
              授权账号
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="create_time" label="注册时间"></el-table-column>
        <el-table-column label="操作" width="220" align="center">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-refresh"
              @click="refreshPwd(scope.$index, scope.row)"
              >重置密码</el-button
            >
            <el-button
              type="text"
               v-if="scope.row.user_type!=5"
              icon="el-icon-refresh"
              @click="refreshtype(scope.$index, scope.row)"
              >授权账号</el-button
            >
             <el-button
              type="text"
               v-if="scope.row.apply_status==1"
              icon="el-icon-refresh"
              @click="AuditUser(scope.$index, scope.row)"
              >审核</el-button
            >
             
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout=" prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>


      <!-- 审核弹出框 -->
    <el-dialog title="审核" v-model="AuditVisible" width="30%">
      <el-form ref="Auditform" :model="Auditform" label-width="70px">

         <el-form-item span="10" >
            <el-form-item label="审核类型" >
              <el-radio-group size="medium" v-model="Auditform.apply_status">
                <el-radio :label="2" :value="Auditform.apply_status">审核通过 </el-radio>
                <el-radio :label="3">拒绝通过</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form-item>

 
 
       <el-form-item label="备注" >
          <el-input v-model="Auditform.audit_content"></el-input>
        </el-form-item>
   
      
    
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="AuditVisible = false">取 消</el-button>
          <el-button type="primary" @click="AuditEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="50%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="用户名">
          <el-input v-model="form.user_name"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-change="onChange"
            :auto-upload="false"
          >
            <img v-if="imageUrl" :src="url.baseUrl +imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="用户权限">
          <el-select v-model="form.user_type" placeholder="请选择"  @change="bclxChange">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
  
        
         <el-form-item span="10" v-if="isJxBc">
            <el-form-item label="会员类型" v-if="isJxBc">
              <el-radio-group v-model="form.is_vip" size="medium">
                <el-radio :label="1" :value="form.is_vip">个人会员 </el-radio>
                <el-radio :label="2">企业会员</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form-item>


       <el-form-item label="身份证" v-if="form.user_type==3&&form.is_vip==1">
          <el-input v-model="form.card"></el-input>
        </el-form-item>
      
   
      <el-form-item label="公司名称" v-if="form.user_type==3&&form.is_vip==2">
          <el-input v-model="form.company"></el-input>
        </el-form-item>
       <el-form-item label="公司法人" v-if="form.user_type==3&&form.is_vip==2">
          <el-input v-model="form.legal_person"></el-input>
        </el-form-item>
        <el-form-item label="统一信用代码" prop="unify" v-if="form.user_type==3&&form.is_vip==2">
          <el-input v-model="form.unify"></el-input>
        </el-form-item>

         <el-form-item label="地址" v-if="form.user_type==3">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="营业执照" v-if="form.user_type==3&&form.is_vip==2">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-change="onChangeyingye"
            :auto-upload="false"
          >
            <img v-if="imageUrlyingye" :src="url.baseUrl +imageUrlyingye" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加弹出框 -->

    <el-dialog title="添加" v-model="addVisible" width="50%" destroy-on-close>
      <el-form
        :rules="rules"
        ref="ruleForm"
        :model="formAdd"
        label-width="70px"
      >
        <el-form-item label="用户名" prop="user_name">
          <el-input v-model="formAdd.user_name"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="user_phone">
          <el-input v-model="formAdd.user_phone"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="user_password">
          <el-input v-model="formAdd.user_password"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-change="onChange"
            :auto-upload="false"
          >
            <img v-if="imageUrl" :src="url.baseUrl +imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="用户权限">
          <el-select v-model="formAdd.user_type" placeholder="请选择"  @change="bclxChangeadd">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>



         <el-form-item span="10" v-if="isJxBc">
            <el-form-item label="会员类型" >
              <el-radio-group v-model="formAdd.is_vip" size="medium">
                <el-radio :label="1" :value="formAdd.is_vip">个人会员 </el-radio>
                <el-radio :label="2">企业会员</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-form-item>


     
       <el-form-item label="身份证" prop="card" v-if="formAdd.is_vip==1">
          <el-input v-model="formAdd.card"></el-input>
        </el-form-item>
      
      
       <el-form-item label="公司名称" prop="company" v-if="formAdd.is_vip==2">
          <el-input v-model="formAdd.company"></el-input>
        </el-form-item>
       <el-form-item label="公司法人" prop="legal_person" v-if="formAdd.is_vip==2">
          <el-input v-model="formAdd.legal_person"></el-input>
        </el-form-item>
         <el-form-item label="统一信用代码" prop="unify" v-if="formAdd.is_vip==2">
          <el-input v-model="formAdd.unify"></el-input>
        </el-form-item>
       <el-form-item label="地址" prop="address" v-if="isJxBc">
          <el-input v-model="formAdd.address"></el-input>
        </el-form-item>
        <el-form-item label="营业执照" v-if="formAdd.is_vip==2">
          <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :before-upload="beforeAvatarUpload"
            :on-change="onChangeyingye"
            :auto-upload="false"
          >
            <img v-if="imageUrlyingye" :src="url.baseUrl +imageUrlyingye" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false ">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  addUser,
  getUser,
  fileUpload,
  editUser,
  delUser,
  ResetUser,
  AuditUser,
  ResetPassword,
} from "../../api/index";
import url from "../../api/baseUrl";
export default {
  name: "basetable",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (this.formAdd.user_phone !== "") {
          this.$refs.ruleForm.validateField("user_phone");
        }
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入用户名"));
      } else {
        if (this.formAdd.user_name !== "") {
          this.$refs.ruleForm.validateField("user_name");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.formAdd.user_password !== "") {
          this.$refs.ruleForm.validateField("user_password");
        }
        callback();
      }
    };
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        token: localStorage.getItem("ms_token"),
        search: "",
        type: "用户名",
        startTime: "",
        endTime: "",
      },
      url:'',
      imageUrl: "",
      imageUrlyingye: "",
      file: {},
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      AuditVisible: false,
      pageTotal: 0,
      isJxBc:false,
      form: {
        user_type: "",
        user_name: "",
        user_id: "",
         is_vip: "",
        address: "",
        card: "",
        company: "",
        unify: "",
        legal_person: "",
        business_license: "",
      },
      Auditform: {
        apply_status: 2,
        audit_content: "",
        user_id: "",
        
      },
      formAdd: {
        user_type: "",
        user_name: "",
        user_phone: "",
          unify: "",
        user_password: "",
        user_headportrait: "",
        is_vip: "",
        address: "",
        card: "",
        company: "",
        legal_person: "",
        business_license: "",
        token: localStorage.getItem("ms_token"),
      },
      rules: {
        user_phone: [
          { validator: validatePass, trigger: "blur" },
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "手机号格式不对",
            trigger: "blur",
          },
        ],
        user_password: [{ validator: validatePass2, trigger: "blur" }],
        user_name: [{ validator: validatePass3, trigger: "blur" }],
      },
      options: [
        // {
        //   value: 1,
        //   label: "后台管理员",
        // },
        {
          value: 2,
          label: "操作员",
        },
        {
          value: 3,
          label: "会员",
        },
        {
          value: 4,
          label: "专家 ",
        },
        {
          value: 5,
          label: "授权账号 ",
        },
      ],
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.getData();
    this.url = url
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData() {
      let formdata = new FormData();
      if (this.query.type == "用户名") {
        if (this.query.search) {
          formdata.append("user_name", this.query.search);
        }
      } else if (this.query.type == "手机号") {
        if (this.query.search) {
          formdata.append("user_phone", this.query.search);
        }
        //formdata.append("user_phone", this.query.search);
      }
      if (this.query.startTime) {
        formdata.append("startTime", this.query.startTime);
      } else if (this.query.endTime) {
        formdata.append("endTime", this.query.endTime);
      }
      formdata.append("pageIndex", this.query.pageIndex);
      formdata.append("pageSize", this.query.pageSize);
      formdata.append("token", localStorage.getItem("ms_token"));
      getUser(formdata).then((res) => {
        console.log(res);
        this.tableData = res.Data.Data;
        this.pageTotal = res.Data.Total || 50;
      });
    },
    // 触发搜索按钮
    handleSearch() {
      // this.$set(this.query, "pageIndex", 1);
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("user_id", row.user_id);
          formdata.append("token", localStorage.getItem("ms_token"));
          delUser(formdata).then((res) => {
            if (res.Status == 200) {
              this.$message.success("删除成功");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error(res.Message);
            }
          });
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    //重置密码
    refreshPwd(index, row) {


 this.$confirm("确定要重置密码吗？重置密码为：123456", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
            formdata.append("user_id", row.user_id);
            formdata.append("token", localStorage.getItem("ms_token"));
            ResetPassword(formdata).then((res) => {
              if (res.Status == 200) {
                this.getData();
                this.$message.success("重置密码成功");
              } else {
                this.$message.error(res.Message);
              }
            });
        })
        .catch(() => {});

      
    },
    //设置授权账号
    refreshtype(index, row) {

       this.$confirm("确定要授权账号吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
            formdata.append("user_id", row.user_id);
            formdata.append("token", localStorage.getItem("ms_token"));
            ResetUser(formdata).then((res) => {
              if (res.Status == 200) {
                this.getData();
                this.$message.success("授权账号成功");
              } else {
                this.$message.error(res.Message);
              }
            });
        })
        .catch(() => {});
      
    },
   //设置审核
    AuditUser(index, row) {
      this.AuditVisible=true;
     this.Auditform.user_id = row.user_id;
    },
   AuditEdit(){
      let formdata = new FormData();
      formdata.append("user_id", this.Auditform.user_id);
      formdata.append("audit_content", this.Auditform.audit_content);
      formdata.append("status", this.Auditform.apply_status);
      formdata.append("token", localStorage.getItem("ms_token"));
      AuditUser(formdata).then((res) => {
        if (res.Status == 200) {
           this.getData();
          this.AuditVisible=false;
          this.Auditform.audit_content = "";
          this.Auditform.user_id = "";
          this.Auditform.apply_status = 2;
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.Message);
        }
      });
   },
    //添加
    addclick(){

this.addVisible=true;
this.imageUrl="";
this.imageUrlyingye="";
this.isJxBc=false;
    },
    // 编辑操作
    handleEdit(index, row) {
      this.isJxBc=false;
      this.idx = index;
      this.form.user_name = row.user_name;
      this.form.user_id = row.user_id;
      this.form.user_type = row.user_type;
      this.form.address  = row.address;
      this.form.card  = row.card;
      this.form.is_vip= row.is_vip;
       this.form.unify= row.unify;
      this.form.company  = row.company;
      this.form.legal_person  = row.legal_person;
      this.imageUrl = row.user_headportrait;
      this.imageUrlyingye =row.business_license;
      if(row.user_type==3){
        this.isJxBc=true;
         // if(row.apply_status!=2){
         //        this.form.is_vip=1;
              
         // }
      }

      this.editVisible = true;
    },
  //编辑下拉显示
    bclxChange(selectValue) {
      
       if (selectValue == 3) {
        this.isJxBc = true;
         console.log(this.form.is_vip);
          if(this.form.is_vip==0){
            this.form.is_vip=1;
          }
      } else {
        this.isJxBc = false;
      }
       
    },
    //编辑下拉显示
    bclxChangeadd(selectValue) {
      
       if (selectValue == 3) {
        this.isJxBc = true;
           this.formAdd.is_vip=1;
      } else {
        this.isJxBc = false;
      }
       
    },
    // 保存编辑
    saveEdit() {
      if (this.imageUrl == "") {
        this.$message.error("请上传头像！");
        return;
      } else if (this.form.user_type == "") {
        this.$message.error("请选择权限!");
        return;
      } else if (this.form.user_name == "") {
        this.$message.error("请输入用户名!");
        return;
      }
     
      let formdata = new FormData();
      formdata.append("user_name", this.form.user_name);
      formdata.append("user_id", this.form.user_id);
      formdata.append("user_type", this.form.user_type);
      formdata.append("user_headportrait", this.imageUrl);
      formdata.append("business_license", this.imageUrlyingye);
      formdata.append("unify", this.form.unify);
      formdata.append("is_vip", this.form.is_vip);
      formdata.append("address", this.form.address);
      formdata.append("card", this.form.card);
      formdata.append("company", this.form.company);
      formdata.append("legal_person", this.form.legal_person);
      formdata.append("token", localStorage.getItem("ms_token"));
      editUser(formdata).then((res) => {
        if (res.Status == 200) {
          this.addVisible = false;
          this.form.user_name = "";
          this.form.user_type = "";
          this.form.user_id = "";
           this.form.unify = "";
       this.form.is_vip = "";
 this.form.address = "";
  this.form.card = "";
 this.form.company = "";
  this.form.legal_person = "";

           this.isJxBc= false;
          this.imageUrl = "";
          this.yingyeimageUrl= "";
          this.editVisible = false;
          this.getData();
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    saveAdd() {
      if (this.imageUrl == "") {
        this.$message.error("请上传头像！");
        return;
      } else if (this.formAdd.user_type == "") {
        this.$message.error("请选择权限!");
        return;
      } else if (this.formAdd.user_name == "") {
        this.$message.error("请输入用户名!");
        return;
      } else if (this.formAdd.user_phone == "") {
        this.$message.error("请输入手机号!");
        return;
      } else if (this.formAdd.user_password == "") {
        this.$message.error("请输入密码!");
        return;
      }
      let formdata = new FormData();
      formdata.append("user_name", this.formAdd.user_name);
      formdata.append("user_password", this.formAdd.user_password);
      formdata.append("user_phone", this.formAdd.user_phone);
      formdata.append("user_type", this.formAdd.user_type);
      formdata.append("user_headportrait", this.imageUrl);
      formdata.append("business_license", this.imageUrlyingye);
      formdata.append("is_vip", this.formAdd.is_vip);
      formdata.append("address", this.formAdd.address);
        formdata.append("unify", this.formAdd.unify);
      
      formdata.append("card", this.formAdd.card);
      formdata.append("company", this.formAdd.company);
      formdata.append("legal_person", this.formAdd.legal_person);
      formdata.append("token", localStorage.getItem("ms_token"));
      addUser(formdata).then((res) => {
        if (res.Status == 200) {
          this.addVisible = false;
         
            this.formAdd.unify= "";
       this.formAdd.is_vip = "";
 this.formAdd.address = "";
  this.formAdd.card = "";
 this.formAdd.company = "";
  this.formAdd.legal_person = "";
 this.isJxBc= false;
          this.formAdd.user_name = "";
          this.formAdd.user_password = "";
          this.formAdd.user_phone = "";
          this.formAdd.user_type = "";
          this.imageUrl = "";
           this.imageUrlyingye= "";
          this.getData();
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    handleAvatarSuccess(res, file) {
      this.formAdd.user_headportrait = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
         console.log("beforeAvatarUpload");
       console.log(file);
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    onChange(file) {
       console.log("onChange");
      console.log(file);
      this.file = file;
      const fileObj = this.file !== null ? this.file.raw : null;
      const form = new FormData();
      // 文件对象
      form.append("file", fileObj);
      form.append("file_type", 1);
      form.append("token", localStorage.getItem("ms_token"));
      // 调用保存接口 将form的值全都传过去
      console.log("form");
       console.log(form);
      fileUpload(form).then((res) => {
        this.imageUrl =  res.Data.file_url;
      });
    },
   onChangeyingye(file) {

    
      this.file = file;
      const fileObj = this.file !== null ? this.file.raw : null;
      const form = new FormData();
      // 文件对象

         console.log("onChangeyingye");
      console.log(fileObj);
     
      form.append("file", fileObj);
      form.append("file_type", 1);
      form.append("token", localStorage.getItem("ms_token"));
      console.log(form);
      // 调用保存接口 将form的值全都传过去
      fileUpload(form).then((res) => {
        console.log(res.Data.file_ur);
        this.imageUrlyingye =  res.Data.file_url;
        console.log( "this.imageUrlyingye");
         console.log( this.imageUrlyingye);
      });
    },

    
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
