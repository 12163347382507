<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 意见反馈管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
       <div class="handle-box">
        <!-- <el-button
          type="primary"
          icon="el-icon-delete"
          class="handle-del mr10"
          @click="delAllSelection"
          >批量删除</el-button
        > -->
        <el-date-picker
          v-model="query.startTime"
          type="date"
          placeholder="开始时间"
        >
        </el-date-picker>
        <el-date-picker
          v-model="query.endTime"
          type="date"
          placeholder="结束时间"
        >
        </el-date-picker>
        <el-select v-model="query.type" class="handle-select mr10">
          <el-option key="1" label="反馈人" value="反馈人"></el-option>
          <el-option key="2" label="手机号" value="手机号"></el-option>
          <el-option key="3" label="反馈标题" value="反馈标题"></el-option>
        </el-select>

        <el-input
          v-model="query.search"
          :placeholder="query.type"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="opinion_id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column prop="opinion_title" label="反馈标题"></el-table-column>
        <el-table-column
          prop="opinion_content"
          label="反馈内容"
        ></el-table-column>
        <el-table-column
          prop="opinion_phone"
          label="反馈人"
        ></el-table-column>
         <el-table-column
          prop="user_phone"
          label="联系方式"
        ></el-table-column>
        <el-table-column prop="create_time" width="220" label="创建时间"></el-table-column>
       <el-table-column label="操作" width="220" align="center">
          <template #default="scope">
            <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout=" prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>

  
  </div>
</template>

<script>
import {
  getOpinionList,
  opinionRemove,
} from "../../api/index";
export default {
  name: "basetable",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入内容"));
      } else {
        if (this.formAdd.notice_content !== "") {
          this.$refs.ruleForm.validateField("notice_content");
        }
        callback();
      }
    };
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入标题"));
      } else {
        if (this.formAdd.notice_title !== "") {
          this.$refs.ruleForm.validateField("notice_title");
        }
        callback();
      }
    };
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入密码"));
    //   } else {
    //     if (this.formAdd.user_password !== "") {
    //       this.$refs.ruleForm.validateField("user_password");
    //     }
    //     callback();
    //   }
    // };
    return {
      query: {
        pageIndex: 1,
        pageSize: 5,
        search: "",
        type: "反馈人",
        startTime: "",
        endTime: "",
      },
      imageUrl: "",
      file: {},
      tableData: [],
      editVisible: false,
      addVisible: false,
      pageTotal: 0,
      form: {
        notice_title: "",
        notice_content: "",
        notice_id: "",
      },
      formAdd: {
        notice_title: "",
        notice_content: "",
      },
      rules: {
        notice_title: [{ validator: validatePass, trigger: "blur" }],
        notice_content: [{ validator: validatePass3, trigger: "blur" }],
      },
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData() {
      console.log();
      let formdata = new FormData();
       if (this.query.type == "反馈人") {
        if (this.query.search) {
          formdata.append("opinion_phone", this.query.search);
        }
      } else if (this.query.type == "手机号") {
        if (this.query.search) {
          formdata.append("user_phone", this.query.search);
        }
      } else if (this.query.type == "反馈标题") {
        if (this.query.search) {
          formdata.append("opinion_title", this.query.search);
        }
      }
      if (this.query.startTime) {
        formdata.append("startTime", this.query.startTime);
      }
      if (this.query.endTime) {
        formdata.append("endTime", this.query.endTime);
      }
      formdata.append("pageIndex", this.query.pageIndex);
      formdata.append("pageSize", this.query.pageSize);
      if(this.query.notice_title){
          formdata.append("notice_title", this.query.notice_title);
      }
      formdata.append("token", localStorage.getItem("ms_token"));
      getOpinionList(formdata).then((res) => {
        console.log(res);
        this.tableData = res.Data.Data;
        this.pageTotal = res.Data.Total
      });
    },
    // 触发搜索按钮
    handleSearch() {
      this.query.pageIndex =1
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("opinion_id", row.opinion_id);
          formdata.append("token", localStorage.getItem("ms_token"));
          opinionRemove(formdata).then((res) => {
            if (res.Status == 200) {
              this.$message.success("删除成功");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error(res.Message);
            }
          });
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
    //重置密码
    // refreshPwd(index, row) {
    //   let formdata = new FormData();
    //   formdata.append("user_id", row.user_id);
    //   formdata.append("token", localStorage.getItem("ms_token"));
    //   ResetPassword(formdata).then((res) => {
    //     if (res.Status == 200) {
    //       this.$message.success("重置成功");
    //       this.tableData.splice(index, 1);
    //     } else {
    //       this.$message.error(res.Message);
    //     }
    //   });
    // },
    // 编辑操作
   
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val
      this.getData();
    },
   
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
