<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          ><i class="el-icon-lx-cascades"></i>新手帮助</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div class="container">
      <el-form
        :model="about"
        ref="about"
        label-width="100px"
        class="demo-ruleForm"
        label-position="top"
      >
        <el-form-item label="内容" prop="novice_help">
          <TEditor
            v-if="edit"
            :value="about.novice_help"
            @input="
              (e) => {
                about.novice_help = e;
              }
            "
          />
          <div v-else v-html="about.novice_help"></div>
        </el-form-item>

        <el-form-item v-if="edit">
          <el-button type="primary" @click="saveEdit()">完成</el-button>

          <el-button @click="edit = false">取消</el-button>
        </el-form-item>
        <el-form-item v-else>
          <el-button @click="edit = true">编辑</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getDictionaries, editDictionaries } from "../../api/index";
export default {
  name: "aboutUs",
  data() {
    return {
      about: {
        novice_help: "",
      },
      edit: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let formdata = new FormData();
      formdata.append("token", localStorage.getItem("ms_token"));
      getDictionaries(formdata).then((res) => {
        this.about.novice_help = res.Data.novice_help;
      });
    },
    saveEdit() {
      if (this.about.novice_help == "") {
        this.$message.error("请输入内容");
      }
      let formdata = new FormData();
      formdata.append("set_id", 2);
      formdata.append("novice_help", this.about.novice_help);
      formdata.append("token", localStorage.getItem("ms_token"));
      editDictionaries(formdata).then((res) => {
        if (res.Status == 200) {
          this.edit = false;
          this.$message.success("修改成功");
        } else {
          this.$message.error(res.Message);
        }
      });
    },
  },
};
</script>