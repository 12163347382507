<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 短信模板管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <!-- <el-button
          type="primary"
          icon="el-icon-delete"
          class="handle-del mr10"
          @click="delAllSelection"
          >批量删除</el-button
        > -->
        <el-date-picker
          v-model="query.startTime"
          type="date"
          placeholder="开始时间"
        >
        </el-date-picker>
        <el-date-picker
          v-model="query.endTime"
          type="date"
          placeholder="结束时间"
        >
        </el-date-picker>
        <el-select v-model="query.type" class="handle-select mr10">
          <el-option key="1" label="模板标题" value="模板标题"></el-option>
          <el-option key="2" label="平台编号" value="平台编号"></el-option>
        </el-select>

        <el-input
          v-model="query.search"
          :placeholder="query.type"
          class="handle-input mr10"
        ></el-input>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >搜索</el-button
        >
        <el-button
          type="primary"
          icon="el-icon-plus"
          @click="addclick"
          >添加</el-button
        >
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column prop="ConfigsCode" label="平台编号"></el-table-column>

       
        <el-table-column prop="TempName" label="模板标题"></el-table-column>
       <el-table-column prop="TempCode" label="模板编号"></el-table-column>

        <el-table-column prop="create_time" label="注册时间"></el-table-column>
        <el-table-column label="操作" width="220" align="center">
          <template #default="scope">
           
            
             
            <el-button
              type="text"
              icon="el-icon-edit"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              class="red"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout=" prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>


 

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑" v-model="editVisible" width="30%">
      <el-form ref="form" :model="form" label-width="70px">
        <el-form-item label="模板标题">
          <el-input v-model="form.TempName"></el-input>
        </el-form-item>
       
       <el-form-item label="模板编号">
          <el-input v-model="form.TempCode"></el-input>
        </el-form-item>
      
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveEdit">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 添加弹出框 -->

    <el-dialog title="添加" v-model="addVisible" width="30%" destroy-on-close>
      <el-form
        :rules="rules"
        ref="ruleForm"
        :model="formAdd"
        label-width="70px"
      >
        <el-form-item label="平台编号" prop="ConfigsCode">
          <el-input v-model="formAdd.ConfigsCode"></el-input>
        </el-form-item>
        <el-form-item label="模板标题" prop="TempName">
          <el-input v-model="formAdd.TempName"></el-input>
        </el-form-item>
       
     
       <el-form-item label="模板编号" prop="TempCode" >
          <el-input v-model="formAdd.TempCode"></el-input>
        </el-form-item>
      
      

      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addVisible = false ">取 消</el-button>
          <el-button type="primary" @click="saveAdd">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  addTemplate,
  getTemplate,
 
  editTemplate,
  delTemplate,

} from "../../api/index";
import url from "../../api/baseUrl";
export default {
  name: "basetable",
  data() {
    
    var validatePass3 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入模板标题"));
      } else {
        if (this.formAdd.TempName !== "") {
          this.$refs.ruleForm.validateField("TempName");
        }
        callback();
      }
    };
    
    return {
      query: {
        pageIndex: 1,
        pageSize: 10,
        token: localStorage.getItem("ms_token"),
        search: "",
        type: "模板标题",
        startTime: "",
        endTime: "",
      },
      url:'',
     
      file: {},
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      addVisible: false,
      AuditVisible: false,
      pageTotal: 0,
      isJxBc:false,
      form: {
        TempName: "",
        TempCode: "",
        id: "",
        
      },
      
      formAdd: {
        TempName: "",
        TempCode: "",
        ConfigsCode: "",
        token: localStorage.getItem("ms_token"),
      },
      rules: {
        ConfigsCode: [{ validator: validatePass3, trigger: "blur" }],
      },
     
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.getData();
    this.url = url
  },
  methods: {
    // 获取 easy-mock 的模拟数据
    getData() {
      let formdata = new FormData();
      if (this.query.type == "模板标题") {
        if (this.query.search) {
          formdata.append("TempName", this.query.search);
        }
      } else if (this.query.type == "平台编号") {
        if (this.query.search) {
          formdata.append("ConfigsCode", this.query.search);
        }
      
      }
      if (this.query.startTime) {
        formdata.append("startTime", this.query.startTime);
      } else if (this.query.endTime) {
        formdata.append("endTime", this.query.endTime);
      }
      formdata.append("pageIndex", this.query.pageIndex);
      formdata.append("pageSize", this.query.pageSize);
      formdata.append("token", localStorage.getItem("ms_token"));
      getTemplate(formdata).then((res) => {
        console.log(res);
        this.tableData = res.Data.Data;
        this.pageTotal = res.Data.Total || 50;
      });
    },
    // 触发搜索按钮
    handleSearch() {
      // this.$set(this.query, "pageIndex", 1);
      this.getData();
    },
    // 删除操作
    handleDelete(index, row) {
      // 二次确认删除
      this.$confirm("确定要删除吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          let formdata = new FormData();
          formdata.append("id", row.id);
          formdata.append("token", localStorage.getItem("ms_token"));
          delTemplate(formdata).then((res) => {
            if (res.Status == 200) {
              this.$message.success("删除成功");
              this.tableData.splice(index, 1);
            } else {
              this.$message.error(res.Message);
            }
          });
        })
        .catch(() => {});
    },
    // 多选操作
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    delAllSelection() {
      const length = this.multipleSelection.length;
      let str = "";
      this.delList = this.delList.concat(this.multipleSelection);
      for (let i = 0; i < length; i++) {
        str += this.multipleSelection[i].name + " ";
      }
      this.$message.error(`删除了${str}`);
      this.multipleSelection = [];
    },
   
    //添加
    addclick(){

      this.addVisible=true;

    },
    // 编辑操作
    handleEdit(index, row) {
      
      this.idx = index;
      this.form.TempName = row.TempName;
      this.form.id = row.id;
      this.form.TempCode = row.TempCode;
    
      this.editVisible = true;
    },
  
    // 保存编辑
    saveEdit() {
       if (this.form.TempName == "") {
        this.$message.error("请输入模板标题!");
        return;
      }
      let formdata = new FormData();
      formdata.append("TempName", this.form.TempName);
      formdata.append("id", this.form.id);
      formdata.append("TempCode", this.form.TempCode);
      formdata.append("token", localStorage.getItem("ms_token"));
      editTemplate(formdata).then((res) => {
        if (res.Status == 200) {
          this.addVisible = false;
          this.form.TempName = "";
          this.form.id = "";
          this.form.TempCode = "";
          this.editVisible = false;
          this.getData();
          this.$message.success("操作成功");
        } else {
          this.$message.error(res.Message);
        }
      });
    },
    // 分页导航
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.getData();
    },
    saveAdd() {
       if (this.formAdd.ConfigsCode == "") {
        this.$message.error("请输入平台编码!");
        return;
      } else if (this.formAdd.TempName == "") {
        this.$message.error("请输入模板标题!");
        return;
      } else if (this.formAdd.TempCode == "") {
        this.$message.error("请输入模板编码!");
        return;
      }
      let formdata = new FormData();
      formdata.append("configscode", this.formAdd.ConfigsCode);
      formdata.append("tempname", this.formAdd.TempName);
      formdata.append("tempcode", this.formAdd.TempCode);
      formdata.append("token", localStorage.getItem("ms_token"));
      addTemplate(formdata).then((res) => {
        if (res.Status == 200) {
          this.addVisible = false;  
          this.formAdd.ConfigsCode = "";
          this.formAdd.TempName = "";
          this.formAdd.TempCode = "";
          this.getData();
        } else {
          this.$message.error(res.Message);
        }
      });
    },

    
    

    
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 300px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 14px;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}

.avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
