<template>
  <div>
    <div class="container">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="密码" prop="pass">
          <el-input
            type="password"
            v-model="ruleForm.pass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="ruleForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { modifyPassword } from "../../api/index";
export default {
  name: "uploadPwd",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        pass: "",
        checkPass: "",
      },
      rules: {
        
        pass: [{ validator: validatePass, trigger: "blur" },{
        trigger: 'blur',
        validator: (rule, value, callback) => {
          var passwordreg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6,16}$/
          if (!passwordreg.test(value)) {
            callback(new Error('请输入6-16位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种'))
          }else{
            callback()
          }
        } }],
        checkPass: [{ validator: validatePass2, trigger: "blur" },,{
        trigger: 'blur',
        validator: (rule, value, callback) => {
          var passwordreg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$)([^\u4e00-\u9fa5\s]){6,16}$/
          if (!passwordreg.test(value)) {
            callback(new Error('请输入6-16位英文字母、数字或者符号（除空格），且字母、数字和标点符号至少包含两种'))
          }else{
            callback()
          }
        } }],
      },
    };
  },
  methods: {
    submitForm(formName) {
        if (this.ruleForm.checkPass == ""&&this.ruleForm.pass == "") {
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formdata = new FormData();
          formdata.append("new_password", this.ruleForm.checkPass);
          formdata.append("token", localStorage.getItem("ms_token"));
          modifyPassword(formdata).then((res) => {
            if (res.Status == 200) {
              this.$message.success("修改成功");
            } else {
              this.$message.error(res.Message);
            }
          });
        } else {
        //   this.$message.success("两次密码输入不一致");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
  created() {},
};
</script>

<style scoped>
</style>