<template>
	<div class="">
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item> 栏目管理</el-breadcrumb-item>
				<el-breadcrumb-item> 市场行情</el-breadcrumb-item>
			</el-breadcrumb>
		</div>

		<div class="container">
			<el-tabs v-model="message" type="card" @edit="tabRemove">
				<el-tab-pane label="市场行情列表" name="first">
					<el-table :data="tableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" @selection-change="handleSelectionChange">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="quotation_id" label="ID" width="55" align="center"></el-table-column>
						<el-table-column prop="quotation_title" label="行情标题"></el-table-column>
						<el-table-column prop="dynamic_date" label="行情日期"></el-table-column>
						<el-table-column prop="market_price" label="规格300~400的价格"></el-table-column>
						<el-table-column prop="vip_price" label="规格400~500的价格"></el-table-column>
						<!--<el-table-column
              prop="market_price"
              label="市场价格"
            ></el-table-column>
            <el-table-column
              prop="vip_price"
              label="VIP价格"
            ></el-table-column>-->
						<!-- <el-table-column
              prop="create_time"
              width="220"
              label="创建时间"
            ></el-table-column> -->
						<el-table-column label="操作" width="220" align="center">
							<template #default="scope">
								<el-button type="text" icon="el-icon-lx-copy"
									@click="handleEdit(scope.$index, scope.row)">详情</el-button>
								<el-button type="text" icon="el-icon-lx-copy"
									@click="getComment(scope.$index, scope.row)">评论</el-button>
								<el-button type="text" icon="el-icon-delete" class="red"
									@click="handleDelete(scope.$index, scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<div class="pagination">
						<el-pagination background layout=" prev, pager, next" :current-page="query.pageIndex"
							:page-size="query.pageSize" :total="pageTotal" @current-change="handlePageChange">
						</el-pagination>
					</div>
				</el-tab-pane>
				<el-tab-pane label="添加" name="second">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150" class="demo-ruleForm"
						label-position="top">
						<el-form-item label="行情标题" prop="quotation_title">
							<el-input v-model="ruleForm.quotation_title"></el-input>
						</el-form-item>
						<el-form-item label="行情日期" prop="dynamic_date">
							<el-date-picker value-format="yyyy-MM-dd" v-model="ruleForm.dynamic_date" type="datetime"
								placeholder="选择行情日期">
							</el-date-picker>
						</el-form-item>
						<el-form-item label="规格300~400的价格" prop="market_price">
							<el-input v-model="ruleForm.market_price" type="number"></el-input>
						</el-form-item>
						<el-form-item label="规格400~500的价格" prop="vip_price">
							<el-input v-model="ruleForm.vip_price" type="number"></el-input>
						</el-form-item>
						<el-form-item label="行情内容">
							<TEditor :value="ruleForm.column_content" @input="(e)=>{ruleForm.column_content = e}" />
						</el-form-item>
						<el-form-item label="图片" style="width:300px">
							<el-upload class="avatar-uploader" action="" :show-file-list="false"
								:before-upload="beforeAvatarUploadAdd" :on-change="onChangeAdd" :auto-upload="false">
								<img v-if="imageUrlAdd" :src="url.baseUrl +imageUrlAdd" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="文档" style="width:300px">
							<el-upload class="file-uploader" action="" :show-file-list="false"
								:before-upload="beforeFileUploadAdd" :on-change="onFileChangeAdd" :auto-upload="false">
								<span v-if="fileUrlAdd">{{fileNameAdd}}</span>
								<i v-else class="el-icon-plus file-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<!--<el-form-item label="是否展示详情" prop="is_details">
							<el-select v-model="ruleForm.is_details" placeholder="请选择">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>-->
						<!--<el-form-item label="市场价格" prop="market_price">
              <el-input v-model="ruleForm.market_price"></el-input>
            </el-form-item>
            <el-form-item label="VIP价格" prop="vip_price">
              <el-input v-model="ruleForm.vip_price"></el-input>
            </el-form-item>-->
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">添加</el-button>
							<!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="详情" name="third" v-if="details" :closable="true">
					<el-form :model="ruleEditForm" :rules="rules" ref="ruleEditForm" label-width="100px"
						class="demo-ruleForm" label-position="top">
						<el-form-item label="行情标题" prop="quotation_title">
							<el-input v-model="ruleEditForm.quotation_title" v-if="edit"></el-input>
							<div v-else>{{ ruleEditForm.quotation_title }}</div>
						</el-form-item>
						<el-form-item label="行情日期" prop="dynamic_date">
							<el-date-picker value-format="yyyy-MM-dd" v-model="ruleEditForm.dynamic_date"
								type="datetime" placeholder="选择行情日期" v-if="edit">
							</el-date-picker>
							<div v-else>{{ ruleEditForm.dynamic_date }}</div>
						</el-form-item>
						<el-form-item label="规格300~400的价格" prop="market_price">
							<el-input v-model="ruleEditForm.market_price" type="number" v-if="edit"></el-input>
							<div v-else>{{ ruleEditForm.market_price }}</div>
						</el-form-item>
						<el-form-item label="规格400~500的价格" prop="vip_price">
							<el-input v-model="ruleEditForm.vip_price" type="number" v-if="edit"></el-input>
							<div v-else>{{ ruleEditForm.vip_price }}</div>
						</el-form-item>
						<el-form-item label="行情内容" prop="column_content">
							<TEditor v-if="edit" :value="ruleEditForm.column_content == 'null' ? '' : ruleEditForm.column_content"
								@input="(e)=>{ruleEditForm.column_content = e}" />
							<div v-else v-html="ruleEditForm.column_content == 'null' ? '' : ruleEditForm.column_content"></div>
						</el-form-item>
						<el-form-item label="图片" style="width:300px">
							<el-upload class="avatar-uploader" action="" :show-file-list="false"
								:before-upload="beforeAvatarUploadEdit" :on-change="onChangeEdit" :auto-upload="false"
								v-if="edit">
								<img v-if="imageUrlEdit" :src="url.baseUrl +imageUrlEdit" class="avatar" />
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
							<img v-else :src="url.baseUrl +imageUrlEdit" class="avatar" />
						</el-form-item>
						<el-form-item label="文档" style="width:300px;">
							<el-upload class="file-uploader" action="" :show-file-list="false"
								:before-upload="beforeFileUploadEdit" :on-change="onFileChangeEdit" :auto-upload="false"
								v-if="edit">
								<span v-if="fileUrlEdit && fileUrlEdit != 'null'">{{fileNameEdit}}</span>
								<i v-else class="el-icon-plus file-uploader-icon"></i>
							</el-upload>
							<a class="file-uploader-link" v-if="!edit && fileUrlEdit && fileUrlEdit != 'null'" :href="url.baseUrl +fileUrlEdit">{{fileNameEdit}}</a>
						</el-form-item>
						<!--<el-form-item label="是否展示详情" prop="is_details">
							<el-select v-model="ruleEditForm.is_details" placeholder="请选择" v-if="edit">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							<div v-else>
								<div v-if="ruleEditForm.is_details === 0">不展示</div>
								<div v-else>展示</div>
							</div>
						</el-form-item>
						<el-form-item label="市场价格" prop="market_price">
							<el-input v-model="ruleEditForm.market_price" type="number" step="0.01" v-if="edit">
							</el-input>
							<div v-else>{{ ruleEditForm.market_price }}</div>
						</el-form-item>
						<el-form-item label="VIP价格" prop="vip_price">
							<el-input v-model="ruleEditForm.vip_price" v-if="edit"></el-input>
							<div v-else>{{ ruleEditForm.vip_price }}</div>
						</el-form-item>-->
						<el-form-item v-if="edit">
							<el-button type="primary" @click="saveEdit('ruleEditForm')">完成</el-button>

							<el-button @click="edit = false">取消</el-button>
						</el-form-item>
						<el-form-item v-else>
							<el-button @click="edit = true">编辑</el-button>
						</el-form-item>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="评论列表" name="four" v-if="comment" :closable="true">
					<el-table :data="commentData" style="width: 100%">
						<el-table-column prop="user_name" label="用户" width="180">
						</el-table-column>
						<el-table-column prop="comment_content" label="评论详情">
						</el-table-column>
						<el-table-column label="操作" width="180" align="center">
							<template #default="scope">
								<el-button type="text" icon="el-icon-delete" class="red"
									@click="commentDelete(scope.$index, scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
	import editorVue from "../../components/editor.vue";
	import {
		getColumnList,
		columnRemove,
		columnCreate,
		columnEdit,
		commentList,
		commentRemove,
		fileUpload,
	} from "../../api/index";
	import url from "../../api/baseUrl";
	export function validatPrices(rule, value, callback) {

		if (rule.required === false && (value === '' || value === null || value === undefined)) {

			callback()

		}
		const isPrice = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
		console.info(isPrice.test(value));
		if (isPrice.test(value)) {

			callback()

		} else {

			callback(new Error(rule.message || '请输入正确的价格，例：10, 10.00'))
		}

	}

	export default {
		name: "tabs",
		components: {
			editorVue
		},
		data() {
			return {
				url: '',
				editorOption: {
					modules: {
						toolbar: {
							handlers: {
								// 表情符
								emoji: function() {},
								image: function(value) {
									if (value) {
										// 点击事件转移到按钮
										document.querySelector("#toolbar-img").click();
									} else {
										this.quill.format("image", false);
									}
								},
							},
						},
						imageResize: {
							modules: ["Resize", "DisplaySize", "Toolbar"],
						},
						"emoji-toolbar": true,
						"emoji-shortname": true,
					},
					placeholder: "请输入内容......",
				},
				query: {
					pageIndex: 1,
					pageSize: 10,
					c_type_id: 4,
				},
				pageTotal: 0,
				ruleForm: {
					quotation_title: "",
					column_content: "",
					is_details: 0,
					dynamic_date: "",
					market_price: "",
					vip_price: "",
				},
				ruleEditForm: {
					quotation_id: "",
					quotation_title: "",
					column_content: "",
					is_details: 0,
					dynamic_date: "",
					market_price: "",
					vip_price: "",
				},
				rules: {
					quotation_title: [{
						required: true,
						message: "请输入行情标题",
						trigger: "blur"
					}, ],
					// column_content: [
					//   { required: true, message: "请输入动态内容", trigger: "blur" },
					// ],
					dynamic_date: [{
						required: true,
						message: "请输入行情日期",
						trigger: "blur"
					}, ],
					market_price: [{
							required: true,
							message: "请输入规格300~400的价格",
							trigger: "blur"
						},

						{
							min: 1,
							max: 6,
							message: '最多包含两位小数的正数且不能为以0开头的正整数',
							trigger: 'blur,change'
						},
						{
							validator: validatPrices,
							message: '最多包含两位小数的正数且不能为以0开头的正整数',
							trigger: 'blur'
						}
					],
					vip_price: [{
							required: true,
							message: "请输入规格400~500的价格",
							trigger: "blur"
						},
						{
							min: 1,
							max: 6,
							message: '最多包含两位小数的正数且不能为以0开头的正整数符',
							trigger: 'blur,change'
						},
						{
							validator: validatPrices,
							message: '最多包含两位小数的正数且不能为以0开头的正整数',
							trigger: 'blur'
						}
					],
				},

				message: "first",
				details: false,
				edit: false,
				tableData: [],
				options: [{
						value: 0,
						label: "不展示",
					},
					{
						value: 1,
						label: "展示",
					},
				],
				comment: false,
				commentData: [],
				imageUrlAdd: "",
				imageUrlEdit: "",
				fileUrlAdd: "",
				fileUrlEdit: "",
				fileNameAdd: "",
				fileNameEdit: "",
			};
		},
		created() {
			this.getData();
			this.url = url
		},


		methods: {

			tabRemove(name) {
				if (name == "third") {
					this.details = false;
				} else {
					this.comment = false;
				}

				this.message = "first";
			},
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			getData() {
				console.log();
				let formdata = new FormData();
				formdata.append("c_type_id", this.query.c_type_id);
				formdata.append("pageIndex", this.query.pageIndex);
				formdata.append("pageSize", this.query.pageSize);
				formdata.append("token", localStorage.getItem("ms_token"));
				getColumnList(formdata).then((res) => {
					this.tableData = res.Data.Data;
					this.pageTotal = res.Data.Total;
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 编辑操作
			handleEdit(index, row) {
				this.message = "third";
				this.details = true;
				this.edit = false;
				console.log(index, row);
				this.idx = index;
				this.ruleEditForm.quotation_id = row.quotation_id;
				this.ruleEditForm.quotation_title = row.quotation_title;
				this.ruleEditForm.column_content = row.column_content;
				//this.ruleEditForm.is_details = row.is_details;
				this.ruleEditForm.dynamic_date = row.dynamic_date;
				this.ruleEditForm.market_price = row.market_price;
				this.ruleEditForm.vip_price = row.vip_price;
				this.imageUrlEdit = row.cover_map;
				this.fileUrlEdit = row.file_path;
				this.fileNameEdit = row.file_name;
			},
			saveEdit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let formdata = new FormData();

						let date = new Date(this.ruleForm.dynamic_date)
						let y = date.getFullYear()
						let m = date.getMonth() + 1
						m = m < 10 ? ('0' + m) : m
						let d = date.getDate()
						d = d < 10 ? ('0' + d) : d
						const time = y + '-' + m + '-' + d;

						formdata.append("dynamic_date", time);
						//formdata.append("is_details", this.ruleEditForm.is_details);
						formdata.append("market_price", this.ruleEditForm.market_price);
						formdata.append("vip_price", this.ruleEditForm.vip_price);
						formdata.append("is_details", 0);
						formdata.append("quotation_id", this.ruleEditForm.quotation_id);
						formdata.append("c_type_id", this.query.c_type_id);
						formdata.append("column_content", this.ruleEditForm.column_content ? this.ruleEditForm.column_content : '');
						formdata.append("quotation_title", this.ruleEditForm.quotation_title);
						formdata.append("cover_map", this.imageUrlEdit ? this.imageUrlEdit : '');
						formdata.append("file_path", this.fileUrlEdit ? this.fileUrlEdit : '');
						formdata.append("file_name", this.fileNameEdit ? this.fileNameEdit : '');
						formdata.append("token", localStorage.getItem("ms_token"));
						columnEdit(formdata).then((res) => {
							if (res.Status == 200) {
								this.details = false;
								this.ruleEditForm.column_content = "";
								this.ruleEditForm.is_details = "";
								this.ruleEditForm.dynamic_date = "";
								this.ruleEditForm.market_price = "";
								this.ruleEditForm.vip_price = "";
								this.ruleEditForm.quotation_title = "";
								this.message = "first";
								this.getData();
								this.$message.success("操作成功");
							} else {
								this.$message.error(res.Message);
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			handleDelete(index, row) {
				// 二次确认删除
				this.$confirm("确定要删除吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						let formdata = new FormData();
						formdata.append("quotation_id", row.quotation_id);
						formdata.append("token", localStorage.getItem("ms_token"));
						columnRemove(formdata).then((res) => {
							if (res.Status == 200) {
								this.$message.success("删除成功");
								this.tableData.splice(index, 1);
							} else {
								this.$message.error(res.Message);
							}
						});
					})
					.catch(() => {});
			},
			// 添加
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let formdata = new FormData();
						// console.log(this.ruleForm.column_content,)
						formdata.append("quotation_title", this.ruleForm.quotation_title);
						formdata.append("column_content", this.ruleForm.column_content ? this.ruleForm.column_content : '');
						formdata.append("cover_map", this.imageUrlAdd ? this.imageUrlAdd : '');
						formdata.append("file_path", this.fileUrlAdd ? this.fileUrlAdd : '');
						formdata.append("file_name", this.fileNameAdd ? this.fileNameAdd: '');
						formdata.append("market_price", this.ruleForm.market_price);
						formdata.append("vip_price", this.ruleForm.vip_price);
						formdata.append("c_type_id", this.query.c_type_id);
						formdata.append("token", localStorage.getItem("ms_token"));

						let date = new Date(this.ruleForm.dynamic_date)
						let y = date.getFullYear()
						let m = date.getMonth() + 1
						m = m < 10 ? ('0' + m) : m
						let d = date.getDate()
						d = d < 10 ? ('0' + d) : d
						const time = y + '-' + m + '-' + d;

						formdata.append("dynamic_date", time);
						//formdata.append("is_details", this.ruleForm.is_details);
						formdata.append("is_details", 0);
						columnCreate(formdata).then((res) => {
							if (res.Status == 200) {
								this.message = "first";
								this.ruleForm.quotation_title = "";
								this.ruleForm.column_content = "";
								this.ruleForm.is_details = "";
								this.ruleForm.dynamic_date = "";
								this.ruleForm.market_price = "";
								this.ruleForm.vip_price = "";
								this.getData();
								this.$message.success("操作成功");
							} else {
								this.$message.error(res.Message);
							}
						});
					} else {
						console.log("error submit!!");
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},

			getComment(index, row) {
				this.comment = true;
				this.message = "four";
				let formdata = new FormData();
				//row.quotation_id
				formdata.append("column_id", row.quotation_id);
				formdata.append("token", localStorage.getItem("ms_token"));
				commentList(formdata).then((res) => {
					if (res.Status == 200) {
						this.commentData = res.Data.Data;
					} else {
						this.$message.error(res.Message);
					}
				});
			},
			
			beforeAvatarUploadAdd(file) {
				const isJPG = file.type === "image/jpeg";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			
			onChangeAdd(file) {
				this.file = file;
				const fileObj = this.file !== null ? this.file.raw : null;
				const form = new FormData();
				// 文件对象
				form.append("file", fileObj);
				form.append("file_type", 2);
				form.append("token", localStorage.getItem("ms_token"));
				// 调用保存接口 将form的值全都传过去
				fileUpload(form).then((res) => {
					this.imageUrlAdd = res.Data.file_url;
				});
			},
			
			beforeAvatarUploadEdit(file) {
				const isJPG = file.type === "image/jpeg";
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error("上传图片只能是 JPG 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传图片大小不能超过 2MB!");
				}
				return isJPG && isLt2M;
			},
			
			onChangeEdit(file) {
				this.file = file;
				const fileObj = this.file !== null ? this.file.raw : null;
				const form = new FormData();
				// 文件对象
				form.append("file", fileObj);
				form.append("file_type", 2);
				form.append("token", localStorage.getItem("ms_token"));
				// 调用保存接口 将form的值全都传过去
				fileUpload(form).then((res) => {
					this.imageUrlEdit = res.Data.file_url;
				});
			},
			
			beforeFileUploadAdd(file) {
				let fileType = [
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				];
				const isJPG = fileType.findIndex((m) => m == file.type) >= 0;
				const isLt2M = file.size / 1024 / 1024 < 3;
				if (!isJPG) {
					this.$message.error("上传文件只能是 Word或Excel 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传文件大小不能超过 3MB!");
				}
				return isJPG && isLt2M;
			},
			
			onFileChangeAdd(file) {
				console.info(file);
				this.file = file;
				const fileObj = this.file !== null ? this.file.raw : null;
				const form = new FormData();
				// 文件对象
				form.append("file", fileObj);
				form.append("file_type", 2);
				form.append("token", localStorage.getItem("ms_token"));
				// 调用保存接口 将form的值全都传过去
				fileUpload(form).then((res) => {
					this.fileUrlAdd = res.Data.file_url;
					this.fileNameAdd = file.name;
				});
			},
			
			beforeFileUploadEdit(file) {
				let fileType = [
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				];
				const isJPG = fileType.findIndex((m) => m == file.type) >= 0;
				const isLt2M = file.size / 1024 / 1024 < 3;
				if (!isJPG) {
					this.$message.error("上传文件只能是 Word或Excel 格式!");
				}
				if (!isLt2M) {
					this.$message.error("上传文件大小不能超过 3MB!");
				}
				return isJPG && isLt2M;
			},
			
			onFileChangeEdit(file) {
				this.file = file;
				const fileObj = this.file !== null ? this.file.raw : null;
				const form = new FormData();
				// 文件对象
				form.append("file", fileObj);
				form.append("file_type", 2);
				form.append("token", localStorage.getItem("ms_token"));
				// 调用保存接口 将form的值全都传过去
				fileUpload(form).then((res) => {
					this.fileUrlEdit = res.Data.file_url;
					this.fileNameEdit = file.name;
				});
			},

			commentDelete(index, row) {
				// 二次确认删除
				this.$confirm("确定要删除吗？", "提示", {
						type: "warning",
					})
					.then(() => {
						let formdata = new FormData();
						formdata.append("comment_id", row.comment_id);
						formdata.append("token", localStorage.getItem("ms_token"));
						commentRemove(formdata).then((res) => {
							if (res.Status == 200) {
								this.$message.success("删除成功");
								this.commentData.splice(index, 1);
							} else {
								this.$message.error(res.Message);
							}
						});
					})
					.catch(() => {});
			},
		},
		computed: {
			unreadNum() {
				return this.unread.length;
			},
		},
	};
</script>

<style>
	.message-title {
		cursor: pointer;
	}

	.handle-row {
		margin-top: 30px;
	}
	
	.avatar {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	.file-uploader {
		line-height: 100%;
	}
	
	.file-uploader .el-upload--text {
		width: 300px;
		height: 50px;
		line-height: 50px;
	}
	
	.file-uploader .file-uploader-icon {
		font-size: 30px;
		line-height: 50px !important;
	}
	.file-uploader-link {
		color: #97a8be;
	}
</style>
